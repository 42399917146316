import { RootState } from '../../types/RootState'

class DeadLetterEventsSelectors {
  getDeadLetterEvents = (state: RootState) => state.deadLetterEvents.deadLetterEvents
  getPaginationTimestamp = (state: RootState) => {
    const createdTimestamp =
      state.deadLetterEvents.deadLetterEvents[state.deadLetterEvents.deadLetterEvents.length - 1]
        ?.createdTimestamp
    if (createdTimestamp) {
      return new Date(createdTimestamp)
    }
  }
  getLoading = (state: RootState) =>
    state.deadLetterEvents.status === 'initial' || state.deadLetterEvents.status === 'loading'
  getInitial = (state: RootState) => state.deadLetterEvents.status === 'initial'
  getHasError = (state: RootState) => state.deadLetterEvents.status === 'failed'
  getError = (state: RootState) => state.deadLetterEvents.error
}

export default new DeadLetterEventsSelectors()
