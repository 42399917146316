import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
  UserCredential,
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  verifyPasswordResetCode,
  signInWithCustomToken,
} from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { AuthToken } from '../../models/AuthToken'

const sleep = async (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms))

// errors from https://firebase.google.com/docs/auth/admin/errors
export type AuthErrorCode =
  | 'auth/claims-too-large'
  | 'auth/email-already-exists'
  | 'auth/id-token-expired'
  | 'auth/id-token-revoked'
  | 'auth/insufficient-permission'
  | 'auth/internal-error'
  | 'auth/invalid-argument'
  | 'auth/invalid-claims'
  | 'auth/invalid-continue-uri'
  | 'auth/invalid-creation-time'
  | 'auth/invalid-credential'
  | 'auth/invalid-disabled-field'
  | 'auth/invalid-display-name'
  | 'auth/invalid-dynamic-link-domain'
  | 'auth/invalid-email'
  | 'auth/invalid-email-verified'
  | 'auth/invalid-hash-algorithm'
  | 'auth/invalid-hash-block-size'
  | 'auth/invalid-hash-derived-key-length'
  | 'auth/invalid-hash-key'
  | 'auth/invalid-hash-memory-cost'
  | 'auth/invalid-hash-parallelization'
  | 'auth/invalid-hash-rounds'
  | 'auth/invalid-hash-salt-separator'
  | 'auth/invalid-id-token'
  | 'auth/invalid-last-sign-in-time'
  | 'auth/invalid-page-token'
  | 'auth/invalid-password'
  | 'auth/invalid-password-hash'
  | 'auth/invalid-password-salt'
  | 'auth/invalid-phone-number'
  | 'auth/invalid-photo-url'
  | 'auth/invalid-provider-data'
  | 'auth/invalid-provider-id'
  | 'auth/invalid-oauth-responsetype'
  | 'auth/invalid-session-cookie-duration'
  | 'auth/invalid-uid'
  | 'auth/invalid-user-import'
  | 'auth/maximum-user-count-exceeded'
  | 'auth/missing-android-pkg-name'
  | 'auth/missing-continue-uri'
  | 'auth/missing-hash-algorithm'
  | 'auth/missing-ios-bundle-id'
  | 'auth/missing-uid'
  | 'auth/missing-oauth-client-secret'
  | 'auth/operation-not-allowed'
  | 'auth/phone-number-already-exists'
  | 'auth/project-not-found'
  | 'auth/reserved-claims'
  | 'auth/session-cookie-expired'
  | 'auth/session-cookie-revoked'
  | 'auth/too-many-requests'
  | 'auth/uid-already-exists'
  | 'auth/unauthorized-continue-uri'
  | 'auth/user-not-found'

interface AuthError extends Error {
  code: AuthErrorCode
}

class AuthService {
  login = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }): Promise<UserCredential> => {
    return setPersistence(getAuth(), browserLocalPersistence)
      .then(() => signInWithEmailAndPassword(getAuth(), email, password))
      .catch((error: AuthError) => {
        if (error.code === 'auth/invalid-credential') {
          throw new Error('Failed to login. Invalid credentials')
        }
        throw new Error('Failed to login. Please try again')
      })
  }
  loginWithToken = async ({ token }: { token: string }): Promise<UserCredential> => {
    return setPersistence(getAuth(), browserLocalPersistence)
      .then(() => signInWithCustomToken(getAuth(), token))
      .catch((error: AuthError) => {
        if (error.code === 'auth/invalid-credential') {
          throw new Error('Failed to login. Invalid credentials')
        }
        throw new Error('Failed to login. Please try again')
      })
  }
  register = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }): Promise<{ authUser: UserCredential; userWasAlreadyRegistered: boolean }> => {
    const register = httpsCallable(getFunctions(undefined, 'europe-west2'), 'register')
    const retryLogin = (attempt: number = 0): Promise<UserCredential> => {
      return setPersistence(getAuth(), browserLocalPersistence)
        .then(() => signInWithEmailAndPassword(getAuth(), email, password))
        .catch((error: AuthError) => {
          if (attempt < 3) {
            return sleep(100).then(() => retryLogin(attempt + 1))
          }
          throw error
        })
    }
    return register({ email, password })
      .then(() => {
        return retryLogin()
          .then(authUser => {
            return {
              authUser,
              userWasAlreadyRegistered: false,
            }
          })
          .catch(() => {
            throw new Error(
              'Registration was successful, but something went wrong when signing in. Please try sign in instead.',
            )
          })
      })
      .catch((error: AuthError) => {
        if (error.message === 'Failed to register. User already exists.') {
          return retryLogin()
            .then(authUser => {
              return {
                authUser,
                userWasAlreadyRegistered: true,
              }
            })
            .catch(() => {
              throw new Error(
                'Failed to register. The email address is already in use by another account',
              )
            })
        }
        throw new Error('Failed to register. Please try again')
      })
  }
  refreshToken = async (): Promise<AuthToken | undefined> => {
    const token = await getAuth().currentUser?.getIdTokenResult(true)
    return token?.claims
  }
  logout = async (): Promise<void> => {
    const currentUser = await getAuth().currentUser
    if (currentUser) {
      await getAuth().signOut()
    }
  }
  subscribeToUserEmailVerified = ({
    onEmailVerified = () => {},
  }: {
    onEmailVerified: () => void
  }) => {
    if (getAuth().currentUser?.emailVerified) {
      onEmailVerified()
      return () => {}
    }
    let subscribed = true
    const checkIfEmailIsVerified = () => {
      const currentUser = getAuth().currentUser
      if (currentUser) {
        return currentUser
          .reload()
          .then(async () => {
            const { currentUser } = getAuth()
            if (subscribed) {
              if (currentUser && currentUser.emailVerified) {
                subscribed = false
                await this.refreshToken()
                onEmailVerified()
                return null
              }
              return sleep(1000).then(checkIfEmailIsVerified)
            }
            return null
          })
          .catch(() => {
            if (subscribed) {
              return sleep(1000).then(checkIfEmailIsVerified)
            }
            return null
          })
      }
      return sleep(1000).then(checkIfEmailIsVerified)
    }
    checkIfEmailIsVerified()
    return () => {
      subscribed = false
    }
  }
  verifyOobCode = async ({ oobCode }: { oobCode: string }) => {
    const verifyOobCodeResponse = await checkActionCode(getAuth(), oobCode)
    return verifyOobCodeResponse
  }
  requestPasswordReset = async ({ email }: { email: string }) => {
    const requestPasswordReset = httpsCallable<{ email: string }, { success: boolean }>(
      getFunctions(undefined, 'europe-west2'),
      'requestPasswordReset',
    )
    const data = await requestPasswordReset({
      email,
    }).then(({ data }) => data)
    return data
  }
  resendVerificationEmail = async () => {
    const resendVerificationEmail = httpsCallable<void, { success: boolean }>(
      getFunctions(undefined, 'europe-west2'),
      'resendVerificationEmail',
    )
    const data = await resendVerificationEmail().then(({ data }) => data)
    return data
  }
  verifyUser = async ({ oobCode }: { oobCode: string }) => {
    await applyActionCode(getAuth(), oobCode)
  }
  verifyPasswordResetCode = async ({ oobCode }: { oobCode: string }) => {
    const email = await verifyPasswordResetCode(getAuth(), oobCode)
    return email
  }
  resetPassword = async ({ oobCode, newPassword }: { oobCode: string; newPassword: string }) => {
    await confirmPasswordReset(getAuth(), oobCode, newPassword)
  }
}

export default new AuthService()
