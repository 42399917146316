import {
  doc,
  setDoc,
  getDocFromServer,
  WriteBatch,
  Transaction,
  UpdateData,
  DocumentSnapshot,
} from 'firebase/firestore'
import { FirebaseError } from 'firebase/app'
import { getStorage, ref, uploadBytes, getMetadata } from 'firebase/storage'
import { Optional } from 'utility-types'
import firestore from '../../firestore'
import { Template } from '@leadgen/models/client'
import config from '../../config'
import BaseService from '../BaseService'

class TemplateService extends BaseService {
  private _previewUrlCache: Promise<Cache> | undefined
  private get previewUrlCache() {
    if (!this._previewUrlCache) {
      this._previewUrlCache = caches.open('template-preview-url-cache')
    }
    return this._previewUrlCache
  }
  previewUrlLocalCache: { [key: string]: Promise<string> | undefined } = {}
  fetchTemplates = async ({
    companyId,
    onTemplateAdded,
    onTemplateUpdated,
  }: {
    companyId: string
    onTemplateAdded: (template: Template) => void
    onTemplateUpdated: (template: Template) => void
  }) => {
    const { data, unsubscribe } = await this.onSnapshot({
      query: firestore.templates(companyId),
      onAdded: onTemplateAdded,
      onUpdated: onTemplateUpdated,
    })
    return {
      data,
      unsubscribe,
    }
  }
  fetchTemplate = async ({
    templateId,
    companyId,
    transaction,
  }: {
    transaction?: Transaction
    templateId: string
    companyId: string
  }): Promise<Template> => {
    const templateRef = doc(firestore.templates(companyId), templateId)
    let templateDoc: DocumentSnapshot
    if (transaction) {
      templateDoc = await transaction.get(templateRef)
    } else {
      templateDoc = await getDocFromServer(templateRef)
    }
    if (!templateDoc.exists()) {
      throw new Error("Template doesn't exist")
    }
    return templateDoc.data() as Template
  }
  updateTemplateVersion = ({
    transactionOrBatch,
    templateId,
    version,
    companyId,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    companyId: string
    templateId: string
    version: number
  }): void | Promise<void> => {
    const url = `${config.bucketBaseUrl}${encodeURIComponent(`templates/${companyId}/${templateId}_${version}.pdf`)}?alt=media`
    const xfdfUrl = `${config.bucketBaseUrl}${encodeURIComponent(`templates/${companyId}/${templateId}_${version}.xml`)}?alt=media`
    const templatesRef = firestore.templates(companyId)
    const templateRef = doc(templatesRef, templateId)
    const templateUpdate: UpdateData<Template> = {
      version,
      generatingPreview: true,
      approved: false,
      url,
      xfdfUrl,
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.update(templateRef, templateUpdate)
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.update(templateRef, templateUpdate)
      }
      return
    }
    return setDoc(templateRef, templateUpdate, { merge: true })
  }
  approveTemplate = ({
    transactionOrBatch,
    templateId,
    companyId,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    companyId: string
    templateId: string
  }): void | Promise<void> => {
    const templatesRef = firestore.templates(companyId)
    const templateRef = doc(templatesRef, templateId)
    const templateUpdate: UpdateData<Template> = {
      approved: true,
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.update(templateRef, templateUpdate)
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.update(templateRef, templateUpdate)
      }
      return
    }
    return setDoc(templateRef, templateUpdate, { merge: true })
  }
  createTemplate = ({
    transactionOrBatch,
    template,
    companyId,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    template: Optional<Template, 'id'>
    companyId: string
  }): Template | Promise<Template> => {
    const templatesRef = firestore.templates(companyId)
    const templateRef = doc(templatesRef, template?.id)
    const completeTemplate: Template = {
      ...template,
      id: templateRef.id,
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.set(templateRef, completeTemplate, { merge: false })
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.set(templateRef, completeTemplate, { merge: false })
      }
      return completeTemplate
    }
    return setDoc(templateRef, completeTemplate, { merge: false }).then(() => {
      return completeTemplate
    })
  }
  uploadPdf = async ({
    pdf,
    templateId,
    companyId,
    version,
  }: {
    pdf: Blob
    templateId: string
    companyId: string
    version: number
  }): Promise<string> => {
    const storage = getStorage()
    const storageRef = ref(storage, `templates/${companyId}/${templateId}_${version}.pdf`)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    await uploadBytes(storageRef, pdf, {
      customMetadata: { version: version.toString() },
      cacheControl: 'public, max-age=3600',
    }).catch((error: Error) => {
      throw new Error(`Failed to upload pdf to the cloud. Error: ${error.message}`)
    })
    return `${config.bucketBaseUrl}${encodeURIComponent(storageRef.fullPath)}?alt=media`
  }
  uploadXfdf = async ({
    xfdf,
    templateId,
    companyId,
    version,
  }: {
    xfdf: Blob
    templateId: string
    companyId: string
    version: number
  }): Promise<string> => {
    const storage = getStorage()
    const storageRef = ref(storage, `templates/${companyId}/${templateId}_${version}.xml`)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    await uploadBytes(storageRef, xfdf, {
      customMetadata: { version: version.toString() },
      contentType: 'application/xml',
    }).catch((error: Error) => {
      throw new Error(`Failed to upload annotations to the cloud. Error: ${error.message}`)
    })
    return `${config.bucketBaseUrl}${encodeURIComponent(storageRef.fullPath)}?alt=media`
  }
  fetchPreviewUrl = async ({
    companyId,
    templateId,
    templateVersion,
  }: {
    companyId: string
    templateId: string
    templateVersion: number
  }) => {
    // const previewUrl = `${config.imgixBaseUrl}previews/${companyId}/${templateId}_${templateVersion}.pdf?page=1&fm=png&auto=format&fit=max&w=1000&h=1400`
    const previewUrl = `${config.bucketBaseUrl}previews${encodeURIComponent(`/${companyId}/${templateId}_${templateVersion}.pdf`)}?alt=media`
    const filePath = `previews/${companyId}/${templateId}_${templateVersion}.pdf`
    if (this.previewUrlLocalCache[filePath]) {
      return this.previewUrlLocalCache[filePath]
    }
    const defered = Promise.withResolvers<string>()
    this.previewUrlLocalCache[filePath] = defered.promise
    const previewUrlCache = await this.previewUrlCache
    const cachedResponse = await previewUrlCache.match(filePath)
    if (cachedResponse) {
      defered.resolve(previewUrl)
      return previewUrl
    }
    const storage = getStorage()
    const storageRef = ref(storage, filePath)
    const previewExists = getMetadata(storageRef)
      .then(async () => {
        await previewUrlCache.put(filePath, new Response())
        return true
      })
      .catch((error: FirebaseError) => {
        if (error.code === 'storage/object-not-found') {
          return false
        }
        delete this.previewUrlLocalCache[filePath]
        throw error
      })
    if (!previewExists) {
      delete this.previewUrlLocalCache[filePath]
      return null
    }

    defered.resolve(previewUrl)
    return previewUrl
  }
}

export default new TemplateService()
