import { useEffect, ReactElement, useMemo, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import authSelectors from '../../../redux/modules/auth/selectors'
import { store } from '../../../redux/store'
import { Role } from '@leadgen/models/client'
import { useShowOnboarding } from '../../providers/OnboardingProvider'
import { getFromOrDefault, routes } from '../../../routes'

const OnboardingRoute = ({
  children,
  allowedRoles = [],
}: {
  children: ReactElement
  allowedRoles: Role[]
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const allowedRolesRef = useRef(allowedRoles)
  const skipRedirectRef = useRef(false)
  const { showOnboarding } = useShowOnboarding()

  const { from, resendEmailPromptDelayed } = (location.state || {}) as {
    from?: string
    resendEmailPromptDelayed?: boolean
  }
  const loggedIn = useMemo(() => {
    return authSelectors.getLoggedIn(store.getState())
  }, [])
  const emailVerified = useMemo(() => {
    return authSelectors.getEmailVerified(store.getState())
  }, [])
  const role = useMemo(() => {
    return authSelectors.getRole(store.getState())
  }, [])
  useEffect(() => {
    if (skipRedirectRef.current) {
      return
    }
    if (!loggedIn) {
      console.info('>>> OnboardingRoute redirect to /login')
      navigate(routes.login, {
        state: { from: from || location.pathname },
        replace: true,
      })
      skipRedirectRef.current = true
      return
    }
    if (!emailVerified) {
      console.info('>>> OnboardingRoute redirect to /await-email-verification')
      navigate(routes.awaitEmailVerification, {
        state: {
          resendEmailPromptDelayed,
          from: from || location.pathname,
        },
        replace: true,
      })
      skipRedirectRef.current = true
      return
    }
    if (allowedRolesRef.current.length && role && !allowedRolesRef.current.includes(role)) {
      console.info('>>> OnboardingRoute redirect to /access-denied')
      navigate(routes.accessDenied, {
        state: { from: from || location.pathname },
        replace: true,
      })
      skipRedirectRef.current = true
      return
    }
    if (showOnboarding === false) {
      navigate(getFromOrDefault(routes.home, from), { replace: true })
      skipRedirectRef.current = true
      return
    }
    if (showOnboarding !== null) {
      skipRedirectRef.current = true
    }
  }, [
    showOnboarding,
    allowedRolesRef,
    emailVerified,
    location.pathname,
    from,
    resendEmailPromptDelayed,
    loggedIn,
    navigate,
    role,
  ])
  return children
}

export default OnboardingRoute
