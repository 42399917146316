import { useEffect, ReactElement, memo, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import authSelectors from '../../../redux/modules/auth/selectors'
import { store } from '../../../redux/store'
import { routes } from '../../../routes'

const PublicRoute = ({ children }: { children: ReactElement }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { from, resendEmailPromptDelayed } = (location.state || {}) as {
    from?: string
    resendEmailPromptDelayed?: boolean
  }
  const loggedIn = useMemo(() => {
    return authSelectors.getLoggedIn(store.getState())
  }, [])
  const emailVerified = useMemo(() => {
    return authSelectors.getEmailVerified(store.getState())
  }, [])
  useEffect(() => {
    if (loggedIn && !emailVerified) {
      console.info('>>> PublicRoute redirect to /await-email-verification')
      navigate(routes.awaitEmailVerification, {
        state: {
          resendEmailPromptDelayed,
          from: from || routes.home,
        },
        replace: true,
      })
      return
    }
  }, [emailVerified, location.pathname, from, resendEmailPromptDelayed, loggedIn, navigate])
  return children
}

export default memo(PublicRoute)
