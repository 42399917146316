import { createSlice } from '@reduxjs/toolkit'
import { DeadLetterEvent } from '@leadgen/models/client'
import authActions from '../auth/actions'
import actions from './actions'

export interface DeadLetterEventsState {
  deadLetterEvents: DeadLetterEvent[]
  error?: string
  status: 'initial' | 'idle' | 'loading' | 'failed'
}

const initialState: DeadLetterEventsState = {
  deadLetterEvents: [],
  status: 'initial',
}

export const deadLetterEventsSlice = createSlice({
  name: 'deadLetterEvents',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.loggedOut.type, () => {
        return initialState
      })
      .addCase(actions.loadDeadLetterEventsFresh.pending, state => {
        state.status = 'loading'
      })
      .addCase(actions.loadDeadLetterEventsFresh.fulfilled, (state, action) => {
        state.status = 'idle'
        state.deadLetterEvents = action.payload.data
        state.error = undefined
      })
      .addCase(actions.loadDeadLetterEventsFresh.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(actions.loadDeadLetterEventsNext.pending, state => {
        state.status = 'loading'
      })
      .addCase(actions.loadDeadLetterEventsNext.fulfilled, (state, action) => {
        state.status = 'idle'
        state.deadLetterEvents.push(...action.payload.data)
        state.error = undefined
      })
      .addCase(actions.loadDeadLetterEventsNext.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(actions.addDeadLetterEvent, (state, action) => {
        if (
          new Date(action.payload.createdTimestamp) >
          new Date(state.deadLetterEvents[0].createdTimestamp)
        ) {
          state.deadLetterEvents.unshift(action.payload)
        }
      })
      .addCase(actions.updateDeadLetterEvent, (state, action) => {
        for (let i = 0, length = state.deadLetterEvents.length; i < length; i++) {
          const deadLetterEvent = state.deadLetterEvents[i]
          if (deadLetterEvent.id === action.payload.id) {
            if (action.payload.status !== 'pending') {
              state.deadLetterEvents.splice(i, 1)
            } else {
              state.deadLetterEvents[i] = action.payload
            }
            break
          }
        }
      })
      .addCase(actions.discardDeadLetterEvent, (state, action) => {
        for (let i = 0, length = state.deadLetterEvents.length; i < length; i++) {
          const deadLetterEvent = state.deadLetterEvents[i]
          if (deadLetterEvent.id === action.payload.id) {
            state.deadLetterEvents.splice(i, 1)
            break
          }
        }
      })
      .addCase(actions.retryDeadLetterEvent, (state, action) => {
        for (let i = 0, length = state.deadLetterEvents.length; i < length; i++) {
          const deadLetterEvent = state.deadLetterEvents[i]
          if (deadLetterEvent.id === action.payload.id) {
            state.deadLetterEvents.splice(i, 1)
            break
          }
        }
      })
  },
})

export default deadLetterEventsSlice
