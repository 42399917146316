import { FC, memo, useCallback, useEffect, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import postcodeDistrictsForm from '../../../../forms/postcodeDistrictsForm'
import { PostcodeDistrict } from '@leadgen/models/client'
import Paper from '@mui/material/Paper'
import DistrictMap, { DistrictMapHandles } from '../DistrictMap'
import useCallbackDebounced from '../../../../hooks/shared/useCallbackDebounced'
import DistrictList, { DistrictListHandles } from '../DistrictList'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

const defaultPostcodeDistricts = []

const EditPostcodeDistrictsPanel: FC<{
  onSave?: () => void
  companyId: string | null
  postcodeDistricts: PostcodeDistrict[] | null
}> = ({ onSave, companyId, postcodeDistricts }) => {
  const districtMapRef = useRef<DistrictMapHandles>(null)
  const districtListRef = useRef<DistrictListHandles>(null)
  const scrollToPostcodeOnList = useCallback((postcodeDistrict: PostcodeDistrict) => {
    districtListRef.current?.scrollToPostcode(postcodeDistrict)
  }, [])
  const scrollToPostcodeOnMap = useCallback((postcodeDistrict: PostcodeDistrict) => {
    districtMapRef.current?.scrollToPostcode(postcodeDistrict)
  }, [])
  const { control, handleSubmit, formState, reset, setError, watch } = useForm<{
    postcodeDistricts: PostcodeDistrict[]
  }>({
    mode: 'onTouched',
    defaultValues: {
      postcodeDistricts: postcodeDistricts || defaultPostcodeDistricts,
    },
  })
  const formPostcodeDistricts = watch('postcodeDistricts')
  useEffect(() => {
    reset(
      {
        postcodeDistricts: postcodeDistricts || defaultPostcodeDistricts,
      },
      { keepDirtyValues: true },
    )
  }, [postcodeDistricts, reset])
  const onSubmit: SubmitHandler<{
    postcodeDistricts: PostcodeDistrict[]
  }> = useCallbackDebounced(
    ({ postcodeDistricts }: { postcodeDistricts: PostcodeDistrict[] }) => {
      if (companyId == null) {
        return
      }
      return postcodeDistrictsForm
        .onSubmit({
          companyId,
          postcodeDistricts,
        })
        .then(() => {
          reset(undefined, { keepValues: true, keepDirty: false, keepDefaultValues: false })
          onSave && onSave()
        })
        .catch((error: Error) => {
          setError('root', { message: error.message })
        })
    },
    [companyId, reset, setError, onSave],
  )

  return (
    <Box
      component="form"
      id="edit-postcode-districts-panel"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        position: 'relative',
      }}
    >
      <Controller
        name="postcodeDistricts"
        control={control}
        render={({ field }) => (
          <>
            <DistrictMap
              ref={districtMapRef}
              postcodeDistricts={field.value}
              onChange={field.onChange}
              onSelect={scrollToPostcodeOnList}
            />
            <Paper
              elevation={1}
              sx={theme => ({
                borderRadius: 1,
                overflow: 'hidden',
                position: 'absolute',
                top: 30,
                bottom: 100,

                [theme.breakpoints.up('sm')]: {
                  right: 30,
                },
                [theme.breakpoints.down('sm')]: {
                  top: 20,
                  bottom: 20,
                  left: 10,
                },
              })}
            >
              <DistrictList
                ref={districtListRef}
                postcodeDistricts={field.value}
                onChange={field.onChange}
                onSelect={scrollToPostcodeOnMap}
              />
            </Paper>
          </>
        )}
        rules={{
          validate: postcodeDistrictsForm.validatePostcodeDistrictsField,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: 30,
          right: 30,
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            marginRight: 2,
          }}
        >
          {formState.errors.root?.message && (
            <Typography color="error" variant="body2">
              {formState.errors.root.message}
            </Typography>
          )}
        </Box>
        <Button
          id="edit-postcode-districts-onboarding-save"
          variant="contained"
          type="submit"
          disabled={
            formState.isSubmitting ||
            !formPostcodeDistricts.length ||
            !formState.dirtyFields.postcodeDistricts
          }
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}

export default memo(EditPostcodeDistrictsPanel)
