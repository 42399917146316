import _ from 'lodash'
import { RootState } from '../../types/RootState'

class TemplateSelectors {
  getTemplate = (state: RootState) => state.template.template
  getLoading = _.memoize(
    ({ loadingWhenInitial }: { loadingWhenInitial: boolean } = { loadingWhenInitial: true }) =>
      (state: RootState) =>
        loadingWhenInitial
          ? state.template.status === 'initial' || state.template.status === 'loading'
          : state.template.status === 'loading',
  )
  getInitial = (state: RootState) => state.template.status === 'initial'
  getHasError = (state: RootState) => state.template.status === 'failed'
  getError = (state: RootState) => state.template.error
}

export default new TemplateSelectors()
