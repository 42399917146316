import './polyfills/promiseWithResolvers'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './initialiseFirebase'
import 'maplibre-gl/dist/maplibre-gl.css'
import CssBaseline from '@mui/material/CssBaseline'
import { createRoot } from 'react-dom/client'
import { ReduxProvider } from './redux/ReduxProvider'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { CompanySubscriptionProvider } from './components/providers/CompanySubscriptionProvider'
import { SnackbarProvider } from './components/providers/SnackbarProvider'
import { OnboardingProvider } from './components/providers/OnboardingProvider'
import { CreateTemplateDialogProvider } from './components/providers/CreateTemplateDialogProvider'
import { PostcodeDistrictsDialogProvider } from './components/providers/PostcodeDistrictsDialogProvider'
import { PlanningCategoriesDialogProvider } from './components/providers/PlanningCategoriesDialogProvider'
import { SubscriptionDialogProvider } from './components/providers/SubscriptionDialogProvider'
import { EditTemplateDialogProvider } from './components/providers/EditTemplateDialogProvider'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'

const container = document.getElementById('root')!
const root = createRoot(container)
root.render(
  <>
    <CssBaseline />
    <ReduxProvider>
      <ThemeProvider theme={theme}>
        <CompanySubscriptionProvider>
          <SnackbarProvider>
            <OnboardingProvider>
              <EditTemplateDialogProvider>
                <CreateTemplateDialogProvider>
                  <PostcodeDistrictsDialogProvider>
                    <PlanningCategoriesDialogProvider>
                      <SubscriptionDialogProvider>
                        <App />
                      </SubscriptionDialogProvider>
                    </PlanningCategoriesDialogProvider>
                  </PostcodeDistrictsDialogProvider>
                </CreateTemplateDialogProvider>
              </EditTemplateDialogProvider>
            </OnboardingProvider>
          </SnackbarProvider>
        </CompanySubscriptionProvider>
      </ThemeProvider>
    </ReduxProvider>
  </>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
