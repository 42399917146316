/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import WebViewer, { Core } from '@pdftron/webviewer'
import { useEffect, useRef } from 'react'
import config from '../../config'
import useStableCallback from '../shared/useStableCallback'

// todo:
// 1. margins and safe area
// 2. change image content via context menu
// 3. text context menu
// 4. customise toolbar

export const useAprysePdf = ({
  readOnly,
  document:
    pdfDocument = `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}/blank.pdf`,
  xfdf,
  saving,
  onSave,
  onClose,
}: {
  readOnly?: boolean
  document?: string
  xfdf: string
  saving?: boolean
  onSave?: (pdf: Blob, xfdfBlob: Blob) => Promise<void>
  onClose?: (hasChanges: boolean) => void
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const saveEnabledRef = useRef<boolean>(false)
  const stableOnSafe = useStableCallback(onSave)
  const stableOnClose = useStableCallback(onClose)
  useEffect(() => {
    if (containerRef.current) {
      WebViewer.WebComponent(
        {
          path: '/webviewer',
          licenseKey: config.aprysePdfLicenseKey,
          fullAPI: true,
          initialDoc: pdfDocument,
          webviewerServerRangeRequests: false,
        },
        containerRef.current,
      ).then(async instance => {
        const { UI, Core } = instance
        const { Tools, PDFNet } = Core

        // First we define four items that will be placed in the header.
        // Please refer to the Items page for more info on the
        // items that can live inside headers.

        // Menu Flyout Button
        const mainMenu = new UI.Components.MainMenu({
          additionalItems: [],
          dataElement: 'mainMenu',
        })
        // View Controls
        debugger
        // const viewControlsToggle = new UI.Components.ViewControls()
        // Zoom Controls
        const zoomControls = new UI.Components.Zoom({
          dataElement: 'zoomControls',
        })
        // Pan Tool Button
        const editButton = new UI.Components.ToolButton({
          dataElement: 'editButton',
          toolName: Tools.ToolNames.EDIT,
        })
        // const imageButton = new UI.Components.ToolButton({
        //   dataElement: 'imageButton',
        //   toolName: Tools.ToolNames.ADD_IMAGE_CONTENT,
        // })
        const stampButton = new UI.Components.ToolButton({
          dataElement: 'stampButton',
          toolName: Tools.ToolNames.STAMP,
          // @ts-expect-error sfdffs
          title: 'Add image',
          // label: 'Add image',
        })
        const freeTextButton = new UI.Components.ToolButton({
          dataElement: 'freeTextButton',
          toolName: Tools.ToolNames.FREETEXT2,
          // @ts-expect-error sfdffs
          title: 'Add text',
          // label: 'Add text',
        })
        // const addParagraphButton = new UI.Components.ToolButton({
        //   dataElement: 'addParagraphButton',
        //   toolName: Tools.ToolNames.ADD_PARAGRAPH,
        // })

        const saveButton = new instance.UI.Components.CustomButton({
          dataElement: 'customButton',
          // @ts-expect-error itsfine
          className: 'custom-button-class',
          label: 'test',
          title: 'this is a test button',
          onClick: async () => {
            if (!stableOnSafe) {
              return
            }
            debugger
            const xfdfString = await instance.Core.annotationManager.exportAnnotations()
            const fileData = await instance.Core.documentViewer
              .getDocument()
              .getFileData({ xfdfString })
            const pdfBlob = new Blob([fileData], { type: 'application/pdf' })
            const xfdfBlob = new Blob([xfdfString], { type: 'application/xml' })
            stableOnSafe(pdfBlob, xfdfBlob)
          },
          img: 'icon-save',
          style: {
            backgroundColor: '#F1F3F5',
          },
        })
        const closeButton = new instance.UI.Components.CustomButton({
          dataElement: 'customButton',
          // @ts-expect-error itsfine
          className: 'custom-button-class',
          label: 'test',
          title: 'this is a test button',
          onClick: async () => {
            if (stableOnClose) {
              stableOnClose(saveEnabledRef.current)
            }
          },
          img: 'icon-close',
          style: {
            backgroundColor: '#F1F3F5',
          },
        })
        // Creater a top header where we will add the above items
        const topHeader = new instance.UI.Components.ModularHeader({
          dataElement: 'default-top-header',
          placement: 'top',
          // grow: 0,
          // gap: 12,
          position: 'start',
          // stroke: true,
          // dimension: {
          //   paddingTop: 8,
          //   paddingBottom: 8,
          //   borderWidth: 1,
          // },
          style: {},
          items: [
            zoomControls,
            // editButton,
            // imageButton,
            stampButton,
            freeTextButton,
            // addParagraphButton,
            saveButton,
            closeButton,
          ],
        })
        debugger
        instance.UI.setModularHeaders([topHeader])
        instance.UI.disableTools([
          Core.Tools.ToolNames.ARC,
          Core.Tools.ToolNames.ARC2,
          Core.Tools.ToolNames.ARC3,
          Core.Tools.ToolNames.ARC4,
          Core.Tools.ToolNames.ARROW,
          Core.Tools.ToolNames.ARROW2,
          Core.Tools.ToolNames.ARROW3,
          Core.Tools.ToolNames.ARROW4,
          Core.Tools.ToolNames.CALLOUT,
          Core.Tools.ToolNames.CALLOUT2,
          Core.Tools.ToolNames.CALLOUT3,
          Core.Tools.ToolNames.CALLOUT4,
          Core.Tools.ToolNames.ELLIPSE,
          Core.Tools.ToolNames.ELLIPSE2,
          Core.Tools.ToolNames.ELLIPSE3,
          Core.Tools.ToolNames.ELLIPSE4,
          Core.Tools.ToolNames.FREEHAND,
          Core.Tools.ToolNames.FREEHAND2,
          Core.Tools.ToolNames.FREEHAND3,
          Core.Tools.ToolNames.FREEHAND4,
          Core.Tools.ToolNames.FREEHAND_HIGHLIGHT,
          Core.Tools.ToolNames.FREEHAND_HIGHLIGHT2,
          Core.Tools.ToolNames.FREEHAND_HIGHLIGHT3,
          Core.Tools.ToolNames.FREEHAND_HIGHLIGHT4,
          Core.Tools.ToolNames.FREETEXT,
          // Core.Tools.ToolNames.FREETEXT2,
          Core.Tools.ToolNames.FREETEXT3,
          Core.Tools.ToolNames.FREETEXT4,
          Core.Tools.ToolNames.DATE_FREETEXT,
          Core.Tools.ToolNames.MARK_INSERT_TEXT,
          Core.Tools.ToolNames.MARK_INSERT_TEXT2,
          Core.Tools.ToolNames.MARK_INSERT_TEXT3,
          Core.Tools.ToolNames.MARK_INSERT_TEXT4,
          Core.Tools.ToolNames.MARK_REPLACE_TEXT,
          Core.Tools.ToolNames.MARK_REPLACE_TEXT2,
          Core.Tools.ToolNames.MARK_REPLACE_TEXT3,
          Core.Tools.ToolNames.MARK_REPLACE_TEXT4,
          Core.Tools.ToolNames.FORM_FILL_CROSS,
          Core.Tools.ToolNames.FORM_FILL_CHECKMARK,
          Core.Tools.ToolNames.FORM_FILL_DOT,
          Core.Tools.ToolNames.LINE,
          Core.Tools.ToolNames.LINE2,
          Core.Tools.ToolNames.LINE3,
          Core.Tools.ToolNames.LINE4,
          Core.Tools.ToolNames.POLYGON,
          Core.Tools.ToolNames.POLYGON2,
          Core.Tools.ToolNames.POLYGON3,
          Core.Tools.ToolNames.POLYGON4,
          Core.Tools.ToolNames.POLYGON_CLOUD,
          Core.Tools.ToolNames.POLYGON_CLOUD2,
          Core.Tools.ToolNames.POLYGON_CLOUD3,
          Core.Tools.ToolNames.POLYGON_CLOUD4,
          Core.Tools.ToolNames.POLYLINE,
          Core.Tools.ToolNames.POLYLINE2,
          Core.Tools.ToolNames.POLYLINE3,
          Core.Tools.ToolNames.POLYLINE4,
          Core.Tools.ToolNames.RECTANGLE,
          Core.Tools.ToolNames.RECTANGLE2,
          Core.Tools.ToolNames.RECTANGLE3,
          Core.Tools.ToolNames.RECTANGLE4,
          Core.Tools.ToolNames.CALIBRATION_MEASUREMENT,
          Core.Tools.ToolNames.DISTANCE_MEASUREMENT,
          Core.Tools.ToolNames.DISTANCE_MEASUREMENT2,
          Core.Tools.ToolNames.DISTANCE_MEASUREMENT3,
          Core.Tools.ToolNames.DISTANCE_MEASUREMENT4,
          Core.Tools.ToolNames.PERIMETER_MEASUREMENT,
          Core.Tools.ToolNames.PERIMETER_MEASUREMENT2,
          Core.Tools.ToolNames.PERIMETER_MEASUREMENT3,
          Core.Tools.ToolNames.PERIMETER_MEASUREMENT4,
          Core.Tools.ToolNames.AREA_MEASUREMENT,
          Core.Tools.ToolNames.AREA_MEASUREMENT2,
          Core.Tools.ToolNames.AREA_MEASUREMENT3,
          Core.Tools.ToolNames.AREA_MEASUREMENT4,
          Core.Tools.ToolNames.CLOUDY_RECTANGULAR_AREA_MEASUREMENT,
          Core.Tools.ToolNames.CLOUDY_RECTANGULAR_AREA_MEASUREMENT2,
          Core.Tools.ToolNames.CLOUDY_RECTANGULAR_AREA_MEASUREMENT3,
          Core.Tools.ToolNames.CLOUDY_RECTANGULAR_AREA_MEASUREMENT4,
          Core.Tools.ToolNames.COUNT_MEASUREMENT,
          Core.Tools.ToolNames.COUNT_MEASUREMENT2,
          Core.Tools.ToolNames.COUNT_MEASUREMENT3,
          Core.Tools.ToolNames.COUNT_MEASUREMENT4,
          Core.Tools.ToolNames.ARC_MEASUREMENT,
          Core.Tools.ToolNames.ARC_MEASUREMENT2,
          Core.Tools.ToolNames.ARC_MEASUREMENT3,
          Core.Tools.ToolNames.ARC_MEASUREMENT4,
          Core.Tools.ToolNames.ELLIPSE_MEASUREMENT,
          Core.Tools.ToolNames.ELLIPSE_MEASUREMENT2,
          Core.Tools.ToolNames.ELLIPSE_MEASUREMENT3,
          Core.Tools.ToolNames.ELLIPSE_MEASUREMENT4,
          Core.Tools.ToolNames.RECTANGULAR_AREA_MEASUREMENT,
          Core.Tools.ToolNames.RECTANGULAR_AREA_MEASUREMENT2,
          Core.Tools.ToolNames.RECTANGULAR_AREA_MEASUREMENT3,
          Core.Tools.ToolNames.RECTANGULAR_AREA_MEASUREMENT4,
          Core.Tools.ToolNames.SIGNATURE,
          // Core.Tools.ToolNames.STAMP,
          Core.Tools.ToolNames.FILEATTACHMENT,
          Core.Tools.ToolNames.RUBBER_STAMP,
          Core.Tools.ToolNames.STICKY,
          Core.Tools.ToolNames.STICKY2,
          Core.Tools.ToolNames.STICKY3,
          Core.Tools.ToolNames.STICKY4,
          Core.Tools.ToolNames.HIGHLIGHT,
          Core.Tools.ToolNames.HIGHLIGHT2,
          Core.Tools.ToolNames.HIGHLIGHT3,
          Core.Tools.ToolNames.HIGHLIGHT4,
          Core.Tools.ToolNames.SQUIGGLY,
          Core.Tools.ToolNames.SQUIGGLY2,
          Core.Tools.ToolNames.SQUIGGLY3,
          Core.Tools.ToolNames.SQUIGGLY4,
          Core.Tools.ToolNames.STRIKEOUT,
          Core.Tools.ToolNames.STRIKEOUT2,
          Core.Tools.ToolNames.STRIKEOUT3,
          Core.Tools.ToolNames.STRIKEOUT4,
          Core.Tools.ToolNames.UNDERLINE,
          Core.Tools.ToolNames.UNDERLINE2,
          Core.Tools.ToolNames.UNDERLINE3,
          Core.Tools.ToolNames.UNDERLINE4,
          Core.Tools.ToolNames.REDACTION,
          Core.Tools.ToolNames.REDACTION2,
          Core.Tools.ToolNames.REDACTION3,
          Core.Tools.ToolNames.REDACTION4,
          Core.Tools.ToolNames.TEXT_SELECT,
          Core.Tools.ToolNames.OFFICE_EDITOR_CONTENT_SELECT,
          // Core.Tools.ToolNames.EDIT,
          Core.Tools.ToolNames.PAN,
          Core.Tools.ToolNames.CROP,
          Core.Tools.ToolNames.SNIPPING,
          Core.Tools.ToolNames.MARQUEE,
          Core.Tools.ToolNames.ERASER,
          Core.Tools.ToolNames.CONTENT_EDIT,
          Core.Tools.ToolNames.ADD_PARAGRAPH,
          Core.Tools.ToolNames.ADD_IMAGE_CONTENT,
          Core.Tools.ToolNames.TEXT_FORM_FIELD,
          Core.Tools.ToolNames.TEXT_FORM_FIELD2,
          Core.Tools.ToolNames.TEXT_FORM_FIELD3,
          Core.Tools.ToolNames.TEXT_FORM_FIELD4,
          Core.Tools.ToolNames.SIG_FORM_FIELD,
          Core.Tools.ToolNames.SIG_FORM_FIELD2,
          Core.Tools.ToolNames.SIG_FORM_FIELD3,
          Core.Tools.ToolNames.SIG_FORM_FIELD4,
          Core.Tools.ToolNames.CHECK_BOX_FIELD,
          Core.Tools.ToolNames.CHECK_BOX_FIELD2,
          Core.Tools.ToolNames.CHECK_BOX_FIELD3,
          Core.Tools.ToolNames.CHECK_BOX_FIELD4,
          Core.Tools.ToolNames.RADIO_FORM_FIELD,
          Core.Tools.ToolNames.RADIO_FORM_FIELD2,
          Core.Tools.ToolNames.RADIO_FORM_FIELD3,
          Core.Tools.ToolNames.RADIO_FORM_FIELD4,
          Core.Tools.ToolNames.LIST_BOX_FIELD,
          Core.Tools.ToolNames.LIST_BOX_FIELD2,
          Core.Tools.ToolNames.LIST_BOX_FIELD3,
          Core.Tools.ToolNames.LIST_BOX_FIELD4,
          Core.Tools.ToolNames.COMBO_BOX_FIELD,
          Core.Tools.ToolNames.COMBO_BOX_FIELD2,
          Core.Tools.ToolNames.COMBO_BOX_FIELD3,
          Core.Tools.ToolNames.COMBO_BOX_FIELD4,
          // EDIT
          // ADD_IMAGE_CONTENT
          // STAMP
          // FREETEXT2
          // ADD_PARAGRAPH
        ])

        await PDFNet.initialize()
        instance.Core.documentViewer.addEventListener('documentLoaded', async () => {
          const { documentViewer, annotationManager, Annotations, Document } = instance.Core
          instance.Core.documentViewer.zoomTo(1.0)
          if (xfdf) {
            await annotationManager.importAnnotations(xfdf)
          }
          setupContextMenus()
          await renderSafeArea2()
          function setupContextMenus() {
            // remove hover overlay
            instance.UI.setAnnotationContentOverlayHandler(annotation => {
              return null
            })

            debugger
            const annotationPopupItemsMap = (
              instance.UI.annotationPopup.getItems() as { dataElement: string }[]
            ).reduce(
              (memo, next) => {
                memo[next.dataElement] = next
                return memo
              },
              {} as Record<string, { dataElement: string }>,
            )
            debugger

            const editImageAnnotationPopupButton = {
              type: 'actionButton',
              img: 'icon-tool-image-line',
              title: 'Edit image',
              // img: 'icon-save',
              onClick: () => alert('clicked'),
            }

            // const contextMenuPopupPopupItemsMap = (
            //   instance.UI.contextMenuPopup.getItems() as { dataElement: string }[]
            // ).reduce(
            //   (memo, next) => {
            //     debugger
            //     memo[next.dataElement] = next
            //     return memo
            //   },
            //   {} as Record<string, { dataElement: string }>,
            // )
            // Updating existing sticky tool button in context menu popup with new label and new on click handler
            // instance.UI.updateElement('freeTextToolButton', {
            //   // label: 'new-label',
            //   onClick: () => instance.UI.setToolMode(Core.Tools.ToolNames.FREETEXT2),
            // })
            debugger
            // instance.UI.annotationPopup.update([])

            instance.UI.disableElements(['contextMenuPopup', 'annotationPopup'])

            debugger
            let skip = false
            annotationManager.addEventListener(
              'annotationSelected',
              (annotations: Core.Annotations.Annotation[], action) => {
                if (skip) {
                  skip = false
                  return
                }
                if (action === 'selected') {
                  if (annotations.length > 1) {
                    return
                  }
                  debugger
                  const isText = annotations.some(annotation => {
                    return annotation instanceof instance.Core.Annotations.FreeTextAnnotation
                  })
                  if (isText) {
                    instance.UI.setToolMode(Core.Tools.ToolNames.FREETEXT2)
                    skip = true
                    debugger
                    annotationManager.selectAnnotation(annotations[0])
                    instance.UI.disableElements(['annotationPopup'])
                    instance.UI.enableElements(['stylePanel'])
                    instance.UI.openElements(['stylePanel'])

                    setTimeout(() => {
                      instance.UI.annotationPopup.update([
                        annotationPopupItemsMap['annotationDeleteButton'],
                        // editImageAnnotationPopupButton,
                      ])
                      instance.UI.enableElements(['annotationPopup'])
                      instance.UI.openElements(['annotationPopup'])

                      //   alert('opened')
                    }, 300)
                  }
                } else {
                  debugger
                  const isText = annotations.some(annotation => {
                    return annotation instanceof instance.Core.Annotations.FreeTextAnnotation
                  })
                  if (isText) {
                    instance.UI.setToolMode(Core.Tools.ToolNames.EDIT)
                    // alert('text deselected')
                    // instance.UI.closeElements(['stylePanel'])
                    // alert('text deselected')

                    instance.UI.disableElements(['stylePanel', 'annotationPopup'])
                    instance.UI.closeElements(['stylePanel', 'annotationPopup'])

                    // instance.UI.contextMenuPopup.add({
                    //   type: 'actionButton',
                    //   img: 'path/to/image',
                    //   onClick: instance.downloadPdf,
                    // });

                    // setTimeout(() => {
                    //   alert('text deselected')
                    //   instance.UI.closeElements(['stylePanel'])
                    // }, 0)
                  }
                }
              },
            )
          }
          async function renderSafeArea2() {
            documentViewer.addEventListener('pageComplete', e => {
              // Use the page number to query the DOM for the page element.
              const viewerElement = documentViewer.getViewerElement()
              const pageElement = viewerElement.querySelector(`#pageWidgetContainer1`)

              debugger
              // If the page element exists and no overlay is added yet
              if (pageElement && !pageElement.querySelector('.bleed-overlay')) {
                // Ensure the page container is relatively positioned.
                debugger

                // //@ts-expect-error fine
                // pageElement.style.position = 'relative'

                // Create an overlay element for bleed margins.
                const overlay = document.createElement('div')
                overlay.className = 'bleed-overlay'
                overlay.style.position = 'absolute'
                overlay.style.top = '0'
                overlay.style.bottom = '0'
                overlay.style.left = '0'
                overlay.style.width = '9px'
                overlay.style.height = '100%'
                overlay.style.pointerEvents = 'none'
                overlay.style.opacity = '0.3'
                overlay.style.backgroundColor = 'green'
                overlay.style.transformOrigin = 'top left'

                const overlay2 = document.createElement('div')
                overlay2.className = 'bleed-overlay'
                overlay2.style.position = 'absolute'
                overlay2.style.top = '0'
                overlay2.style.bottom = '0'
                overlay2.style.right = '0'
                overlay2.style.width = '9px'
                overlay2.style.height = '100%'
                overlay2.style.pointerEvents = 'none'
                overlay2.style.opacity = '0.3'
                overlay2.style.backgroundColor = 'green'
                overlay2.style.transformOrigin = 'top right'

                const overlay3 = document.createElement('div')
                overlay3.className = 'bleed-overlay2'
                overlay3.style.position = 'absolute'
                overlay3.style.top = '0'
                overlay3.style.left = '0'
                overlay3.style.right = '0'
                overlay3.style.height = '9px'
                overlay3.style.width = '100%'
                overlay3.style.pointerEvents = 'none'
                overlay3.style.opacity = '0.3'
                overlay3.style.backgroundColor = 'green'
                overlay3.style.transformOrigin = 'top left'

                const overlay4 = document.createElement('div')
                overlay4.className = 'bleed-overlay2'
                overlay4.style.position = 'absolute'
                overlay4.style.bottom = '0'
                overlay4.style.left = '0'
                overlay4.style.right = '0'
                overlay4.style.height = '9px'
                overlay4.style.width = '100%'
                overlay4.style.pointerEvents = 'none'
                overlay4.style.opacity = '0.3'
                overlay4.style.backgroundColor = 'green'
                overlay4.style.transformOrigin = 'bottom left'

                const overlay5 = document.createElement('div')
                overlay5.className = 'bleed-overlay3'
                overlay5.style.position = 'absolute'
                overlay5.style.top = '0'
                overlay5.style.left = '0'
                // overlay5.style.border = '2px solid pink'

                overlay5.style.height = '252px'
                overlay5.style.width = '340px'
                overlay5.style.pointerEvents = 'none'
                overlay5.style.transformOrigin = 'top left'
                overlay5.style.display = 'flex'
                overlay5.style.alignItems = 'flex-end'
                overlay5.style.justifyContent = 'flex-end'

                const overlay6 = document.createElement('div')
                overlay6.style.height = '128px'
                overlay6.style.width = '281px'
                overlay6.style.pointerEvents = 'none'
                overlay6.style.opacity = '0.6'
                overlay6.style.backgroundColor = 'green'

                overlay5.appendChild(overlay6)

                pageElement.appendChild(overlay)
                pageElement.appendChild(overlay2)
                pageElement.appendChild(overlay3)
                pageElement.appendChild(overlay4)
                pageElement.appendChild(overlay5)
              }
            })
            instance.Core.documentViewer.addEventListener('zoomUpdated', scale => {
              const viewerElement = documentViewer.getViewerElement()
              const pageElement = viewerElement.querySelector(`#pageWidgetContainer1`)
              // e.newZoomFactor contains the updated zoom level.
              // Calculate the new scale for your overlay elements based on this factor.
              // For example:
              // const scale = e.newZoomFactor
              const bleedOverlays = pageElement?.querySelectorAll('.bleed-overlay')
              debugger
              bleedOverlays?.forEach(overlay => {
                // @ts-expect-error sfdfsd
                overlay.style.transform = `scale(${scale}, 1)`
              })

              const bleedOverlays2 = pageElement?.querySelectorAll('.bleed-overlay2')
              debugger
              bleedOverlays2?.forEach(overlay => {
                // @ts-expect-error sfdfsd
                overlay.style.transform = `scale(1, ${scale})`
              })
              const bleedOverlays3 = pageElement?.querySelectorAll('.bleed-overlay3')
              debugger
              bleedOverlays3?.forEach(overlay => {
                // @ts-expect-error sfdfsd
                overlay.style.transform = `scale(${scale})`
              })
            })
          }

          // async function renderSafeArea() {
          //   const doc = await instance.Core.documentViewer.getDocument().getPDFDoc()

          //   const pageNum = 1
          //   const page = await doc.getPage(pageNum)
          //   const mediaBox = await page.getMediaBox() // PDF coordinate space: origin at bottom-left

          //   const left = mediaBox.x1
          //   const right = mediaBox.x2
          //   const bottom = mediaBox.y1
          //   const top = mediaBox.y2

          //   // Helper function to create a line annotation
          //   const createLineAnnotation = (x1, y1, x2, y2) => {
          //     const lineAnnotation = new Annotations.LineAnnotation()
          //     lineAnnotation.PageNumber = pageNum
          //     lineAnnotation.setStartPoint(x1, y1)
          //     lineAnnotation.setEndPoint(x2, y2)
          //     lineAnnotation.StrokeColor = new Annotations.Color(255, 0, 0, 1)
          //     lineAnnotation.StrokeThickness = 15
          //     lineAnnotation.Opacity = 0.3
          //     lineAnnotation.ReadOnly = true
          //     lineAnnotation.IsHoverable = false
          //     lineAnnotation.IsClickableOutsideRect = false
          //     lineAnnotation.setCustomData('page-safe-area', 'bleed-margin')
          //     lineAnnotation.Listable = false
          //     lineAnnotation.Locked = true
          //     lineAnnotation.LockedContents = true
          //     return lineAnnotation
          //   }
          //   const createAddressBox = () => {
          //     // Calculate coordinates:
          //     // x coordinate is 60 from the left.
          //     // y coordinate for the top of the box: since PDF y=0 is the bottom,
          //     // subtract 126 from the top edge of the page.
          //     const x1 = 60
          //     const y2 = mediaBox.y2 - 126
          //     const boxWidth = 200
          //     const boxHeight = 50
          //     const x2 = x1 + boxWidth
          //     const y1 = y2 - boxHeight

          //     // Create a rectangle annotation for the address box
          //     const rectangleAnnotation = new Annotations.RectangleAnnotation()
          //     rectangleAnnotation.PageNumber = pageNum
          //     // Use setRect to define the box boundaries in PDF coordinate space
          //     // rectangleAnnotation.setRect(new Core.Math.Rect(x1, y1, x2, y2))
          //     rectangleAnnotation.setWidth(200)
          //     rectangleAnnotation.setHeight(100)
          //     rectangleAnnotation.setX(60)
          //     rectangleAnnotation.setY(60)
          //     rectangleAnnotation.setCustomData('page-safe-area', 'address-box')

          //     // Style the annotation – here we only draw a border
          //     rectangleAnnotation.StrokeThickness = 1
          //     rectangleAnnotation.StrokeColor = new Annotations.Color(0, 0, 255, 1) // blue border
          //     // Optionally make the fill transparent
          //     rectangleAnnotation.FillColor = new Annotations.Color(255, 255, 255, 1)
          //     rectangleAnnotation.ReadOnly = true
          //     rectangleAnnotation.IsHoverable = false
          //     rectangleAnnotation.IsClickableOutsideRect = false
          //     rectangleAnnotation.Listable = false
          //     rectangleAnnotation.Locked = true
          //     rectangleAnnotation.LockedContents = true
          //     return rectangleAnnotation
          //   }

          //   // Create annotations for top, bottom, left, and right margins.
          //   const topLine = createLineAnnotation(left, top, right, top)
          //   const bottomLine = createLineAnnotation(left, bottom, right, bottom)
          //   const leftLine = createLineAnnotation(left, bottom, left, top)
          //   const rightLine = createLineAnnotation(right, bottom, right, top)
          //   const addressBox = createAddressBox()

          //   // Add the annotations to the manager and refresh the view
          //   annotationManager.addAnnotation(addressBox)
          //   annotationManager.redrawAnnotation(addressBox)
          //   annotationManager.addAnnotation(topLine)
          //   annotationManager.redrawAnnotation(topLine)
          //   annotationManager.addAnnotation(bottomLine)
          //   annotationManager.redrawAnnotation(bottomLine)
          //   annotationManager.addAnnotation(leftLine)
          //   annotationManager.redrawAnnotation(leftLine)
          //   annotationManager.addAnnotation(rightLine)
          //   annotationManager.redrawAnnotation(rightLine)
          // }
        })
        // instance.UI.loadDocument(pdfDocument)
        // instance.UI.loadDocument('https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf')
      })
    }
  }, [stableOnClose, pdfDocument, stableOnSafe, xfdf])

  return { containerRef }
}
