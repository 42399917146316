import { FC, Suspense, lazy, memo } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import SuspensePanel from './components/atoms/SuspensePanel'
import PrivateRoute from './components/routeSecurity/PrivateRoute'
import PublicRoute from './components/routeSecurity/PublicRoute'
import AwaitEmailVerificationRoute from './components/routeSecurity/AwaitEmailVerificationRoute'
import { useSubscribeToAuthState } from './hooks/auth/useSubscribeToAuthState'
import { useRefreshAuthTokenOnClaimsChange } from './hooks/auth/useRefreshAuthTokenOnClaimsChange'
import { useSubscribeToCompanyChanges } from './hooks/company/useSubscribeToCompanyChanges'
import SuperAdminSwitchRoute from './components/routeSecurity/SuperAdminSwitchRoute'
import OnboardingRoute from './components/routeSecurity/OnboardingRoute'
import { routes } from './routes'

const LoginPage = lazy(() => import('./components/pages/LoginPage'))
const TokenLoginPage = lazy(() => import('./components/pages/TokenLoginPage'))
const RegisterPage = lazy(() => import('./components/pages/RegisterPage'))
const DashboardPage = lazy(() => import('./components/pages/DashboardPage'))
const OnboardingPage = lazy(() => import('./components/pages/OnboardingPage'))
const AdminDashboardPage = lazy(() => import('./components/pages/AdminDashboardPage'))
const DeadLetterEventsPage = lazy(() => import('./components/pages/DeadLetterEventsPage'))
const VerifyPage = lazy(() => import('./components/pages/VerifyPage'))
const WelcomePage = lazy(() => import('./components/pages/WelcomePage'))
const ResetPasswordSuccessPage = lazy(() => import('./components/pages/ResetPasswordSuccessPage'))
const RequestPasswordResetPage = lazy(() => import('./components/pages/RequestPasswordResetPage'))
const AwaitEmailVerificationPage = lazy(
  () => import('./components/pages/AwaitEmailVerificationPage'),
)
const NoMatchPage = lazy(() => import('./components/pages/NoMatchPage'))

const App: FC = () => {
  useSubscribeToAuthState()
  useRefreshAuthTokenOnClaimsChange()
  useSubscribeToCompanyChanges()
  return (
    <Router>
      <Suspense fallback={<SuspensePanel />}>
        <Routes>
          <Route
            path={routes.home}
            element={
              <PrivateRoute allowedRoles={['user', 'owner', 'super-admin']}>
                <SuperAdminSwitchRoute
                  superAdminPage={<AdminDashboardPage />}
                  regularPage={<DashboardPage />}
                />
              </PrivateRoute>
            }
          />
          <Route
            path={routes.retries}
            element={
              <PrivateRoute allowedRoles={['super-admin']}>
                <DeadLetterEventsPage />
              </PrivateRoute>
            }
          />
          <Route
            path={routes.onboarding}
            element={
              <OnboardingRoute allowedRoles={['owner']}>
                <OnboardingPage />
              </OnboardingRoute>
            }
          />
          <Route
            path={routes.awaitEmailVerification}
            element={
              <AwaitEmailVerificationRoute>
                <AwaitEmailVerificationPage />
              </AwaitEmailVerificationRoute>
            }
          />
          <Route
            path={routes.verify}
            element={
              <PublicRoute>
                <VerifyPage />
              </PublicRoute>
            }
          />
          <Route
            path={routes.welcome}
            element={
              <PublicRoute>
                <WelcomePage />
              </PublicRoute>
            }
          />
          <Route
            path={routes.resetPasswordSuccess}
            element={
              <PublicRoute>
                <ResetPasswordSuccessPage />
              </PublicRoute>
            }
          />

          <Route
            path={routes.resetPassword}
            element={
              <PublicRoute>
                <RequestPasswordResetPage />
              </PublicRoute>
            }
          />
          <Route
            path={routes.login}
            element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            }
          />
          <Route
            path={routes.tokenLogin}
            element={
              <PublicRoute>
                <TokenLoginPage />
              </PublicRoute>
            }
          />
          <Route
            path={routes.register}
            element={
              <PublicRoute>
                <RegisterPage />
              </PublicRoute>
            }
          />
          <Route path="*" element={<NoMatchPage />} />
        </Routes>
      </Suspense>
    </Router>
  )
}

export default memo(App)
