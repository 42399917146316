import {
  query,
  getDocs,
  limit,
  startAfter,
  orderBy,
  WriteBatch,
  Transaction,
  doc,
  UpdateData,
  setDoc,
  where,
} from 'firebase/firestore'
import firestore from '../../firestore'
import { PlanningRecord } from '@leadgen/models/client'
import BaseService from '../BaseService'

class PlanningRecordService extends BaseService {
  readonly pageSize = 3
  fetchPlanningRecords = async (): Promise<PlanningRecord[]> => {
    const planningRecordsSnapshot = await getDocs(firestore.planningRecords())
    return planningRecordsSnapshot.docs.map(planningRecordDoc => planningRecordDoc.data())
  }
  fetchPlanningRecordsFirstPage = async ({
    onPlanningRecordAdded,
    onPlanningRecordUpdated,
  }: {
    onPlanningRecordAdded: (template: PlanningRecord) => void
    onPlanningRecordUpdated: (template: PlanningRecord) => void
  }) => {
    const { data, unsubscribe } = await this.onSnapshot({
      query: query(
        firestore.planningRecords(),
        where('status', '==', 'awaiting-validation'),
        orderBy('createdTimestamp', 'desc'),
        limit(this.pageSize),
      ),
      onAdded: onPlanningRecordAdded,
      onUpdated: onPlanningRecordUpdated,
    })
    return {
      data,
      unsubscribe,
    }
  }

  fetchPlanningRecordsNextPage = async ({
    startAfter: startAfterTimestamp,
    onPlanningRecordUpdated,
  }: {
    startAfter: Date
    onPlanningRecordUpdated: (template: PlanningRecord) => void
  }) => {
    const { data, unsubscribe } = await this.onSnapshot({
      query: query(
        firestore.planningRecords(),
        where('status', '==', 'awaiting-validation'),
        orderBy('createdTimestamp', 'desc'),
        startAfter(startAfterTimestamp),
        limit(this.pageSize),
      ),
      onUpdated: onPlanningRecordUpdated,
    })
    return {
      data,
      unsubscribe,
    }
  }

  approvePlanningRecord = ({
    transactionOrBatch,
    planningRecord,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    planningRecord: Pick<
      PlanningRecord,
      | 'id'
      | 'applicantTitle'
      | 'applicantFirstName'
      | 'applicantLastName'
      | 'applicantAddress1'
      | 'applicantAddress2'
      | 'applicantPostcode'
      | 'applicantTown'
      | 'categories'
      | 'postcode'
      | 'postcodeDistrict'
    >
  }): void | Promise<void> => {
    const planningRecordsRef = firestore.planningRecords()
    const planningRecordRef = doc(planningRecordsRef, planningRecord.id)
    const planningRecordUpdate: UpdateData<PlanningRecord> = {
      ...planningRecord,
      status: 'validated',
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.update(planningRecordRef, planningRecordUpdate)
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.update(planningRecordRef, planningRecordUpdate)
      }
      return
    }
    return setDoc(planningRecordRef, planningRecordUpdate, { merge: true })
  }

  discardPlanningRecord = ({
    transactionOrBatch,
    planningRecordId,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    planningRecordId: string
  }): void | Promise<void> => {
    const planningRecordsRef = firestore.planningRecords()
    const planningRecordRef = doc(planningRecordsRef, planningRecordId)
    const planningRecordUpdate: UpdateData<PlanningRecord> = {
      status: 'discarded',
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.update(planningRecordRef, planningRecordUpdate)
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.update(planningRecordRef, planningRecordUpdate)
      }
      return
    }
    return setDoc(planningRecordRef, planningRecordUpdate, { merge: true })
  }
}

export default new PlanningRecordService()
