import { useState, useMemo, useContext, createContext } from 'react'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import useCallbackDebounced from '../../../hooks/shared/useCallbackDebounced'
import DialogSlideRightToLeftTransition from '../../atoms/DialogSlideRightToLeftTransition'
import { SubscriptionPanel } from '../../features/subscription/SubscriptionPanel'
import { SubscriptionProvider } from '../SubscriptionProvider'
import { IconButton } from '@mui/material'

const SubscriptionDialogContext = createContext<{
  openSubscriptionDialog: () => void
  closeSubscriptionDialog: () => void
} | null>(null)

const useSubscriptionDialog = () => {
  const context = useContext(SubscriptionDialogContext)
  if (!context) {
    throw new Error('no SubscriptionDialogProvider found when calling useSubscriptionDialog()')
  }
  return context
}

const SubscriptionDialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const openSubscriptionDialog = useCallbackDebounced(() => {
    setOpen(true)
  }, [])
  const closeSubscriptionDialog = useCallbackDebounced(() => {
    setOpen(false)
  }, [])
  const value = useMemo(
    () => ({
      openSubscriptionDialog,
      closeSubscriptionDialog,
    }),
    [closeSubscriptionDialog, openSubscriptionDialog],
  )
  return (
    <SubscriptionDialogContext.Provider value={value}>
      {children}
      <Dialog
        open={open}
        TransitionComponent={DialogSlideRightToLeftTransition}
        onClose={closeSubscriptionDialog}
        sx={theme => ({
          '& .MuiDialog-paper': {
            maxWidth: '70vw',
            height: '100vh',
            width: 1000,
            position: 'fixed',
            top: 0,
            right: 0,
            margin: 0,
            maxHeight: 'none',
            borderRadius: 0,
            backgroundColor: 'pageBackground',
          },
          [theme.breakpoints.down('sm')]: {
            '& .MuiDialog-paper': {
              maxWidth: '100vw',
              width: '100vw',
              height: '100vh',
            },
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <SubscriptionProvider>
            <SubscriptionPanel />
          </SubscriptionProvider>
          <Box
            sx={theme => ({
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              display: 'none',
              pointerEvents: 'none',
              [theme.breakpoints.down('sm')]: {
                display: 'block',
              },
            })}
          >
            <Box
              sx={theme => ({
                position: 'relative',
                flex: 1,
                [theme.breakpoints.down('sm')]: {
                  display: 'flex',
                  padding: 2,
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                },
              })}
            >
              <Box
                sx={theme => ({
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  [theme.breakpoints.down('sm')]: {
                    zIndex: 1,
                    backgroundColor: 'pageBackground',
                    ['-webkit-mask-image']:
                      '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))',
                  },
                })}
              />
              <IconButton
                onClick={closeSubscriptionDialog}
                sx={{
                  backgroundColor: 'white',
                  pointerEvents: 'visible',
                  zIndex: 1,
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </SubscriptionDialogContext.Provider>
  )
}

export { useSubscriptionDialog, SubscriptionDialogProvider }
export default SubscriptionDialogProvider
