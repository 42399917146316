import { createSlice } from '@reduxjs/toolkit'
import { TemplatePendingReview } from '@leadgen/models/client'
import authActions from '../auth/actions'
import actions from './actions'

export interface TemplatesPendingReviewState {
  templatesPendingReview: TemplatePendingReview[]
  error?: string
  status: 'initial' | 'idle' | 'loading' | 'failed'
  currentRequest?: string
}

const initialState: TemplatesPendingReviewState = {
  templatesPendingReview: [],
  status: 'initial',
}

export const templatesPendingReviewSlice = createSlice({
  name: 'templatesPendingReview',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.loggedOut.type, () => {
        return initialState
      })
      .addCase(actions.addTemplate, (state, action) => {
        const templateIsAlreadyListed =
          state.templatesPendingReview.findIndex(template => template.id === action.payload.id) >= 0
        if (!templateIsAlreadyListed) {
          state.templatesPendingReview.push(action.payload)
        }
      })
      .addCase(actions.removeTemplate, (state, action) => {
        const indexOfTemplate = state.templatesPendingReview.findIndex(
          template => template.id === action.payload.id,
        )
        const templateIsAlreadyListed = indexOfTemplate >= 0
        if (templateIsAlreadyListed) {
          state.templatesPendingReview.splice(indexOfTemplate, 1)
        }
      })
      .addCase(actions.loadTemplatesPendingReview.pending, (state, action) => {
        state.status = 'loading'
        state.currentRequest = action.meta.requestId
      })
      .addCase(actions.loadTemplatesPendingReview.fulfilled, (state, action) => {
        if (state.currentRequest === action.meta.requestId) {
          state.status = 'idle'
          state.templatesPendingReview = action.payload.data
          state.error = undefined
        } else {
          action.payload.unsubscribe()
        }
      })
      .addCase(actions.loadTemplatesPendingReview.rejected, (state, action) => {
        if (state.currentRequest === action.meta.requestId) {
          state.status = 'failed'
          state.error = action.error.message
        }
      })
  },
})

export default templatesPendingReviewSlice
