import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import deadLetterEventService from '../../../services/deadLetterEventService'
import { DeadLetterEvent } from '@leadgen/models/client'
import { RootState } from '../../types/RootState'
import selectors from './selectors'

class DeadLetterEventsActions {
  loadDeadLetterEventsFresh = createAsyncThunk(
    'deadLetterEvents/loadDeadLetterEventsFresh',
    async (params, { dispatch }) => {
      const response = await deadLetterEventService.fetchDeadLetterEventsFirstPage({
        onDeadLetterEventAdded: deadLetterEvent => {
          dispatch(this.addDeadLetterEvent(deadLetterEvent))
        },
        onDeadLetterEventUpdated: deadLetterEvent => {
          dispatch(this.updateDeadLetterEvent(deadLetterEvent))
        },
      })
      return response
    },
  )
  loadDeadLetterEventsNext = createAsyncThunk<
    Awaited<ReturnType<typeof deadLetterEventService.fetchDeadLetterEventsNextPage>>,
    undefined,
    { state: RootState }
  >('deadLetterEvents/loadDeadLetterEventsNext', async (params, { dispatch, getState }) => {
    const latestTimestamp = selectors.getPaginationTimestamp(getState())
    if (!latestTimestamp) {
      throw new Error('Invalid pagination timestamp')
    }
    const response = await deadLetterEventService.fetchDeadLetterEventsNextPage({
      startAfter: latestTimestamp,
      onDeadLetterEventUpdated: deadLetterEvent => {
        dispatch(this.updateDeadLetterEvent(deadLetterEvent))
      },
    })
    return response
  })
  addDeadLetterEvent = createAction(
    'deadLetterEvents/addDeadLetterEvent',
    (deadLetterEvent: DeadLetterEvent) => ({
      payload: deadLetterEvent,
    }),
  )
  updateDeadLetterEvent = createAction(
    'deadLetterEvents/updateDeadLetterEvent',
    (deadLetterEvent: DeadLetterEvent) => ({
      payload: deadLetterEvent,
    }),
  )
  retryDeadLetterEvent = createAction(
    'deadLetterEvents/retryDeadLetterEvent',
    (deadLetterEvent: DeadLetterEvent) => ({
      payload: deadLetterEvent,
    }),
  )
  discardDeadLetterEvent = createAction(
    'deadLetterEvents/discardDeadLetterEvent',
    (deadLetterEvent: DeadLetterEvent) => ({
      payload: deadLetterEvent,
    }),
  )
}

export default new DeadLetterEventsActions()
