import {
  doc,
  getDocFromServer,
  Transaction,
  WriteBatch,
  UpdateData,
  setDoc,
  DocumentSnapshot,
} from 'firebase/firestore'
import firestore from '../../firestore'
import { Company, PlanningCategory, PostcodeDistrict } from '@leadgen/models/client'
import BaseService from '../BaseService'

class CompanyService extends BaseService {
  subscribeToCompany = async ({
    companyId,
    onCompanyUpdated,
  }: {
    companyId: string
    onCompanyUpdated: (company: Company) => void
  }) => {
    const { data, unsubscribe } = await this.onDoc({
      ref: doc(firestore.companies(), companyId),
      onUpdated: onCompanyUpdated,
    })
    if (data == null) {
      throw new Error("Company doesn't exist")
    }
    return {
      data,
      unsubscribe,
    }
  }

  fetchCompany = async ({
    companyId,
    transaction,
  }: {
    transaction?: Transaction
    companyId: string
  }): Promise<Company> => {
    const companyRef = doc(firestore.companies(), companyId)
    let companyDoc: DocumentSnapshot
    if (transaction) {
      companyDoc = await transaction.get(companyRef)
    } else {
      companyDoc = await getDocFromServer(companyRef)
    }
    if (!companyDoc.exists()) {
      throw new Error("Company doesn't exist")
    }
    return companyDoc.data() as Company
  }

  approveCompanyTemplatePendingReview = ({
    transactionOrBatch,
    companyId,
    templateId,
    templateVersion,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    companyId: string
    templateId: string
    templateVersion: number
  }): void | Promise<void> => {
    const companiesRef = firestore.companies()
    const companyRef = doc(companiesRef, companyId)
    const companyUpdate: UpdateData<Company> = {
      pendingReviewTemplateId: null,
      pendingReviewTemplateVersion: null,
      pendingReviewTemplateFeedback: null,
      defaultTemplateId: templateId,
      defaultTemplateVersion: templateVersion,
      hasDefaultTemplate: true,
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.update(companyRef, companyUpdate)
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.update(companyRef, companyUpdate)
      }
      return
    }
    return setDoc(companyRef, companyUpdate, { merge: true })
  }
  declineCompanyTemplatePendingReview = ({
    transactionOrBatch,
    companyId,
    pendingReviewTemplateFeedback,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    companyId: string
    pendingReviewTemplateFeedback: string
  }): void | Promise<void> => {
    const companiesRef = firestore.companies()
    const companyRef = doc(companiesRef, companyId)
    const companyUpdate: UpdateData<Company> = {
      pendingReviewTemplateFeedback,
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.update(companyRef, companyUpdate)
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.update(companyRef, companyUpdate)
      }
      return
    }
    return setDoc(companyRef, companyUpdate, { merge: true })
  }

  updateCompanyPendingReviewTemplate = ({
    transactionOrBatch,
    companyId,
    pendingReviewTemplateId,
    pendingReviewTemplateVersion,
    pendingReviewTemplateFeedback,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    companyId: string
    pendingReviewTemplateId: string
    pendingReviewTemplateVersion: number
    pendingReviewTemplateFeedback?: string | null
  }): void | Promise<void> => {
    const companiesRef = firestore.companies()
    const companyRef = doc(companiesRef, companyId)
    const companyUpdate: UpdateData<Company> = {
      pendingReviewTemplateId,
      pendingReviewTemplateVersion,
      pendingReviewTemplateFeedback: pendingReviewTemplateFeedback || null,
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.update(companyRef, companyUpdate)
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.update(companyRef, companyUpdate)
      }
      return
    }
    return setDoc(companyRef, companyUpdate, { merge: true })
  }

  updateMonthlyCreditLimit = ({
    transactionOrBatch,
    companyId,
    monthlyCreditLimit,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    companyId: string
    monthlyCreditLimit: number
  }): void | Promise<void> => {
    const companiesRef = firestore.companies()
    const companyRef = doc(companiesRef, companyId)
    const companyUpdate: UpdateData<Company> = {
      monthlyCreditLimit,
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.update(companyRef, companyUpdate)
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.update(companyRef, companyUpdate)
      }
      return
    }
    return setDoc(companyRef, companyUpdate, { merge: true })
  }
  updateLastChangedTemplate = ({
    transactionOrBatch,
    companyId,
    lastChangedTemplateId,
    lastChangedTemplateVersion,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    companyId: string
    lastChangedTemplateId: string
    lastChangedTemplateVersion: number
  }): void | Promise<void> => {
    const companiesRef = firestore.companies()
    const companyRef = doc(companiesRef, companyId)
    const companyUpdate: UpdateData<Company> = {
      lastChangedTemplateId,
      lastChangedTemplateVersion,
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.update(companyRef, companyUpdate)
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.update(companyRef, companyUpdate)
      }
      return
    }
    return setDoc(companyRef, companyUpdate, { merge: true })
  }

  updatePlanningCategories = ({
    transactionOrBatch,
    companyId,
    planningCategories,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    companyId: string
    planningCategories: PlanningCategory[]
  }): void | Promise<void> => {
    const companiesRef = firestore.companies()
    const companyRef = doc(companiesRef, companyId)
    const _basePlanningCategories: Company['_planningCategories'] = {
      'tree-surgery': false,
      'kitchen-fitting': false,
      'bathroom-fitting': false,
      flooring: false,
      'general-building': false,
      'painting-and-decorating': false,
      glazing: false,
      landscaping: false,
    }
    const companyUpdate: UpdateData<Company> = {
      planningCategories,
      _planningCategories: planningCategories.reduce((memo, next) => {
        memo[next] = true
        return memo
      }, _basePlanningCategories),
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.update(companyRef, companyUpdate)
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.update(companyRef, companyUpdate)
      }
      return
    }
    return setDoc(companyRef, companyUpdate, { merge: true })
  }

  updatePostcodeDistricts = ({
    transactionOrBatch,
    companyId,
    postcodeDistricts,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    companyId: string
    postcodeDistricts: PostcodeDistrict[]
  }): void | Promise<void> => {
    const companiesRef = firestore.companies()
    const companyRef = doc(companiesRef, companyId)
    const companyUpdate: UpdateData<Company> = {
      postcodeDistricts,
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.update(companyRef, companyUpdate)
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.update(companyRef, companyUpdate)
      }
      return
    }
    return setDoc(companyRef, companyUpdate, { merge: true })
  }
}

export default new CompanyService()
