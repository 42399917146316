import { runTransaction, getFirestore, doc } from 'firebase/firestore'
import firestore from '../../firestore'
import templateService from '../../services/templateService'
import { Template } from '@leadgen/models/client'
import config from '../../config'
import companyService from '../../services/companyService'

class TemplateForm {
  onSubmitCreate = async ({
    pdf,
    xfdf,
    companyId,
  }: {
    pdf: Blob
    xfdf: Blob
    companyId: string
  }): Promise<Template> => {
    const templateId = doc(firestore.templates(companyId)).id
    const url = `${config.bucketBaseUrl}${encodeURIComponent(`templates/${companyId}/${templateId}_1.pdf`)}?alt=media`
    const xfdfUrl = `${config.bucketBaseUrl}${encodeURIComponent(`templates/${companyId}/${templateId}_1.xml`)}?alt=media`
    let uploadFileError: Error
    const template: Template = await runTransaction(getFirestore(), async transaction => {
      const template = templateService.createTemplate({
        transactionOrBatch: transaction,
        companyId,
        template: {
          id: templateId,
          url,
          xfdfUrl,
          version: 1,
          generatingPreview: true,
          approved: false,
        },
      }) as Template
      companyService.updateLastChangedTemplate({
        transactionOrBatch: transaction,
        companyId,
        lastChangedTemplateId: templateId,
        lastChangedTemplateVersion: 1,
      })
      await templateService.uploadPdf({
        pdf,
        templateId,
        companyId,
        version: 1,
      })
      await templateService
        .uploadXfdf({
          xfdf,
          templateId,
          companyId,
          version: 1,
        })
        .catch((error: Error) => {
          uploadFileError = error
          throw error
        })
      return template
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }).catch((error: Error) => {
      // if for some reason the transaction fails after our code successfully uploaded the pdf to storage, the backend process will inevitably create the missing document with the same id if it doesnt exist. so we continue down the happy path
      // the main reason for creating the template doc here is to try cover the few second delay between the pdf being uploaded and the doc being created on the server during which the user may refresh the page to see a fresh templates list which should include this template if possible
      if (!uploadFileError) {
        const template: Template = {
          id: templateId,
          url,
          xfdfUrl,
          version: 1,
          generatingPreview: true,
          approved: false,
        }
        return template
      }
      throw error
    })
    return template
  }
  onSubmitUpdate = async ({
    pdf,
    xfdf,
    companyId,
    templateId,
  }: {
    pdf: Blob
    xfdf: Blob
    companyId: string
    templateId: string
  }): Promise<number> => {
    return runTransaction(getFirestore(), async transaction => {
      // if something updates the template we want it to retry the transaction. uploading the pdf is idempotent so its fine. it will however trigger multiple storage function onObjectFinalized calls which will all wait til the firestore template document version is at least as high as the one on the file metadata and settle down
      const [company, template] = await Promise.all([
        companyService.fetchCompany({ transaction, companyId }),
        templateService.fetchTemplate({ transaction, companyId, templateId }),
      ])
      const version = template.version + 1
      if (company.pendingReviewTemplateId === templateId) {
        companyService.updateCompanyPendingReviewTemplate({
          transactionOrBatch: transaction,
          companyId,
          pendingReviewTemplateId: templateId,
          pendingReviewTemplateVersion: version,
        })
      }
      templateService.updateTemplateVersion({
        transactionOrBatch: transaction,
        companyId,
        version,
        templateId,
      })
      companyService.updateLastChangedTemplate({
        transactionOrBatch: transaction,
        companyId,
        lastChangedTemplateId: templateId,
        lastChangedTemplateVersion: version,
      })
      await templateService.uploadPdf({
        pdf,
        templateId,
        companyId,
        version,
      })
      await templateService.uploadXfdf({
        xfdf,
        templateId,
        companyId,
        version,
      })
      return version
    })
  }
}

export default new TemplateForm()
