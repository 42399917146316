import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Theme {
    // palette: {
    //   pageBackground: string
    // }
  }
  // allow configuration using `createTheme()`
  interface ThemeOptions {
    // status?: {
    //   danger?: string
    // }
    // palette: {
    //   pageBackground: string
    // }
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    pageBackground: string
  }
  interface PaletteOptions {
    pageBackground: string
    pdfBackground: string
  }
}

// theme generator
// https://m2.material.io/inline-tools/color/

export const theme = createTheme({
  palette: {
    text: {
      primary: '#2A3255',
      secondary: '#65759e',
    },
    secondary: {
      '50': '#ffecf0',
      '100': '#ffd0d6',
      '200': '#f29fa1',
      '300': '#ea7a7d',
      main: '#f65c5c',
      '400': '#f65c5c',
      '500': '#fb4c43',
      '600': '#ed4442',
      '700': '#db3b3c',
      '800': '#ce3435',
      '900': '#bf2929',
    },
    primary: {
      '50': '#eaeafd',
      '100': '#cac9fa',
      '200': '#a6a7f7',
      '300': '#8083f5',
      main: '#6365f1',
      '400': '#6365f1',
      '500': '#4746eb',
      '600': '#423ddf',
      '700': '#3931d3',
      '800': '#3123c8',
      '900': '#2900af',
    },
    pageBackground: '#fafbff',
    pdfBackground: '#f7f8fa',
  },
})
