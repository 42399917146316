import { useState, useEffect, useRef, useMemo, useContext, createContext, useCallback } from 'react'
import companySelectors from '../../../redux/modules/company/selectors'
import onboardingSelectors from '../../../redux/modules/onboarding/selectors'
import onboardingActions from '../../../redux/modules/onboarding/actions'
import { useDispatch, useSelector } from '../../../redux/hooks'
import { getAuth, NextOrObserver, User } from 'firebase/auth'
import _ from 'lodash'

const OnboardingContext = createContext<{
  showOnboarding: boolean | null
  onboardingStep: 'coverage' | 'planning-categories' | 'template' | 'subscribe' | null
  setOnboardingStep: (
    onboardingStep: 'coverage' | 'planning-categories' | 'template' | 'subscribe' | null,
  ) => void
} | null>(null)

const useOnboarding = () => {
  const context = useContext(OnboardingContext)
  if (!context) {
    throw new Error('no OnboardingProvider found when calling useOnboarding()')
  }
  return context
}

const useSetOnboardingStep = () => {
  const { setOnboardingStep } = useOnboarding()
  return useMemo(() => setOnboardingStep, [setOnboardingStep])
}

const useShowOnboarding = () => {
  const { showOnboarding } = useOnboarding()
  return useMemo(
    () => ({
      showOnboarding,
    }),
    [showOnboarding],
  )
}

const useOnboardingChecklist = () => {
  const { showOnboarding } = useOnboarding()
  const hasTemplate = useSelector(companySelectors.getCompanyHasTemplate)
  const hasPendingReviewTemplate = useSelector(companySelectors.getCompanyHasPendingReviewTemplate)
  const hasDefaultTemplate = useSelector(companySelectors.getCompanyHasDefaultTemplate)
  const postcodeDistricts = useSelector(companySelectors.getCompanyPostcodeDistricts)
  const planningCategories = useSelector(companySelectors.getCompanyPlanningCategories)
  const hasActiveSubscription = useSelector(companySelectors.getHasActiveSubscription)
  const hasHadSubscription = useSelector(companySelectors.getHasHadSubscription)
  const missingPostCodeDistricts = !postcodeDistricts?.length
  const missingPlanningCategories = !planningCategories?.length
  const missingTemplate = !hasTemplate
  const missingDefaultTemplateSubmitted = !hasDefaultTemplate && !hasPendingReviewTemplate
  const missingDefaultTemplateApproved = !hasDefaultTemplate
  const missingSubscription = !hasActiveSubscription && !hasHadSubscription
  const showOnboardingChecklistCurrent =
    showOnboarding === null
      ? false
      : missingPostCodeDistricts ||
        missingPlanningCategories ||
        missingTemplate ||
        missingDefaultTemplateSubmitted ||
        missingDefaultTemplateApproved ||
        missingSubscription
  const [showOnboardingChecklist, setShowOnboardingChecklist] = useState(
    showOnboardingChecklistCurrent,
  )
  const loadingTrapRef = useRef(showOnboarding === null)
  // loading trap
  // reset it any time after loading finishes
  // - if load finishes and it was true -> leave it as true
  // - if load finishes and it was false -> reset it to new value
  useEffect(() => {
    if (showOnboardingChecklist) {
      return
    }
    if (showOnboarding === null) {
      loadingTrapRef.current = true
      return
    }
    if (showOnboarding !== null && loadingTrapRef.current) {
      loadingTrapRef.current = false
      setShowOnboardingChecklist(showOnboardingChecklistCurrent)
    }
  }, [showOnboarding, showOnboardingChecklist, showOnboardingChecklistCurrent])
  return useMemo(() => {
    return {
      showOnboardingChecklist,
      missingPostCodeDistricts,
      missingPlanningCategories,
      missingTemplate,
      missingDefaultTemplateSubmitted,
      missingDefaultTemplateApproved,
      missingSubscription,
    }
  }, [
    missingDefaultTemplateApproved,
    missingDefaultTemplateSubmitted,
    missingPlanningCategories,
    missingPostCodeDistricts,
    missingSubscription,
    missingTemplate,
    showOnboardingChecklist,
  ])
}
// 1. when there is an onboarding step in the onboarding store use that
// 2. else when there is something missing on the company start there
//      - unless the company has a payment method
//      - or unless the company already has coverage and planning categories
//      - in which case dont show onboarding at all and the other ui will signpost them in the app itself
const OnboardingProvider = ({ children }) => {
  const dispatch = useDispatch()
  const company = useSelector(companySelectors.getCompany)
  const storeOnboardingStep = useSelector(onboardingSelectors.getOnboardingStep)
  const onboardingIntialised = useRef(storeOnboardingStep !== undefined || !!company)
  const nextMissingOnboardingStep = useSelector(companySelectors.getNextMissingOnboarding)
  const [onboardingStep, setOnboardingStepBase] = useState<
    'coverage' | 'planning-categories' | 'template' | 'subscribe' | null
  >(
    storeOnboardingStep !== undefined
      ? storeOnboardingStep
      : company
        ? nextMissingOnboardingStep
        : null,
  )
  const [showOnboarding, setShowOnboarding] = useState(
    storeOnboardingStep !== undefined
      ? storeOnboardingStep !== null
      : company
        ? nextMissingOnboardingStep !== null
        : null,
  )
  useEffect(() => {
    const handler: NextOrObserver<User | null> = _.after(2, (currentUser: User | null) => {
      if (!currentUser) {
        onboardingIntialised.current = false
      }
    })
    return getAuth().onAuthStateChanged(handler)
  }, [])
  useEffect(() => {
    if (!onboardingIntialised.current && (storeOnboardingStep !== undefined || company)) {
      onboardingIntialised.current = true
      setOnboardingStepBase(
        storeOnboardingStep !== undefined
          ? storeOnboardingStep
          : company
            ? nextMissingOnboardingStep
            : null,
      )
      setShowOnboarding(
        storeOnboardingStep !== undefined
          ? storeOnboardingStep !== null
          : company
            ? nextMissingOnboardingStep !== null
            : null,
      )
    }
  }, [company, nextMissingOnboardingStep, storeOnboardingStep])
  const setOnboardingStep = useCallback(
    (onboardingStep: 'coverage' | 'planning-categories' | 'template' | 'subscribe' | null) => {
      setOnboardingStepBase(onboardingStep)
      if (onboardingStep === null) {
        setShowOnboarding(false)
      }
      dispatch(onboardingActions.setOnboardingStep(onboardingStep))
    },
    [dispatch],
  )
  const value = useMemo(
    () => ({
      showOnboarding,
      onboardingStep,
      setOnboardingStep,
    }),
    [onboardingStep, setOnboardingStep, showOnboarding],
  )
  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>
}

export {
  OnboardingProvider,
  useOnboarding,
  useShowOnboarding,
  useSetOnboardingStep,
  useOnboardingChecklist,
}
export default OnboardingProvider
