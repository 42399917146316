export const blankXfdf = `
<?xml version="1.0" encoding="UTF-8" ?>
<xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><pdf-info xmlns="http://www.pdftron.com/pdfinfo"
    version="2" import-version="4"><document-actions>
      <action trigger="Open">
        <goto>
          <dest fit="Fit" page="1" />
        </goto>
      </action>
    </document-actions></pdf-info>
  <fields />
  <annots>
    <freetext page="0" rect="75.91,451.85,305.08,486.008" flags="print" name="0b313d31-2b38-0c8a-1bdd-c8d2ecf731e0"
      title="Guest" subject="Free Text" date="D:20250309222232Z00'00'" width="0" creationdate="D:20250309214327Z00'00'"
      TextColor="#000000" FontSize="28"><trn-custom-data
        bytes="{&quot;trn-auto-size-type&quot;:&quot;auto&quot;,&quot;trn-wrapped-text-lines&quot;:&quot;[\\&quot;Hello {{applicant}}, congratulations on having your planning permission approved at {{address}}. I operate in the {{postcode}} postcode and wanted to offer my services. fdsfds \\&quot;]&quot;}" />
      <contents>Hello {{applicant}}, congratulations on having your planning permission approved at {{address}}. I
        operate in the {{postcode}} postcode and wanted to offer my services. fdsfds</contents><contents-richtext>
        <body>
          <p>
            <span style="color: #000000; font-family: Helvetica; font-size: 28pt">Hello {{applicant}}, congratulations
              on having your planning permission approved at {{address}}. I operate in the {{postcode}} postcode and
              wanted to offer my services. fdsfds</span><span style="font-family: Helvetica" />
          </p>
        </body>
      </contents-richtext>
      <defaultappearance>0 0 0 rg /Helvetica 28 Tf</defaultappearance>
      <defaultstyle>font: Helvetica 28pt; text-align: left; text-vertical-align: top; color:
        #000000</defaultstyle>
    </freetext>
  </annots>
  <pages>
    <defmtx matrix="1,0,0,-1,0,858.89789" />
  </pages>
</xfdf>
`
