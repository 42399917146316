import { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { PostcodeDistrict } from '@leadgen/models/client'
import List from '@mui/material/List'
import useStableCallback from '../../../../hooks/shared/useStableCallback'
import { postcodeDistricts as postcodeDistrictOptions } from '../../../../constants/postcodeDistricts'
import DistrictRow from './DistrictRow'
import _ from 'lodash'
import ListSubheader from '@mui/material/ListSubheader'

export type DistrictListHandles = {
  scrollToPostcode: (postcodeDistrict: PostcodeDistrict) => void
}
// eslint-disable-next-line react/display-name
const DistrictList = forwardRef<
  DistrictListHandles,
  {
    postcodeDistricts: PostcodeDistrict[]
    onChange: (postcodeDistricts: PostcodeDistrict[]) => void
    onSelect?: (postcodeDistrict: PostcodeDistrict) => void
  }
>(({ postcodeDistricts, onChange, onSelect }, ref) => {
  const containerRef = useRef<HTMLUListElement>(null)

  const scrollToPostcode = useCallback((postcodeDistrict: PostcodeDistrict) => {
    const childElement = containerRef.current?.querySelector(
      `#district-list-item-${postcodeDistrict}`,
    )
    childElement?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' })
  }, [])
  useImperativeHandle(ref, () => ({
    scrollToPostcode,
  }))
  const selectedPostcodeDistricts = useMemo(() => {
    return postcodeDistricts.reduce(
      (memo, next) => {
        memo[next] = true
        return memo
      },
      {} as Record<PostcodeDistrict, boolean>,
    )
  }, [postcodeDistricts])
  const onChangePostcodeDistrict = useStableCallback((postcodeDistrict: PostcodeDistrict) => {
    if (postcodeDistricts.includes(postcodeDistrict)) {
      const indexToRemove = postcodeDistricts.indexOf(postcodeDistrict)
      onChange([
        ...postcodeDistricts.slice(0, indexToRemove),
        ...postcodeDistricts.slice(indexToRemove + 1),
      ])
      if (onSelect) {
        onSelect(postcodeDistrict)
      }
    } else {
      onChange([...postcodeDistricts, postcodeDistrict])
      if (onSelect) {
        onSelect(postcodeDistrict)
      }
    }
  })
  const options = useMemo(() => {
    return _.chain(postcodeDistrictOptions)
      .groupBy(postcodeDistrict => postcodeDistrict.match(/^[A-Za-z]+/)?.[0])
      .entries()
      .map(([groupName, postcodeDistrictGroup]) => ({
        groupName,
        postcodeDistrictGroup: _.orderBy(
          postcodeDistrictGroup,
          postcodeDistrict => parseInt(postcodeDistrict.replace(/^[A-Za-z]+/, ''), 10),
          'asc',
        ),
      }))
      .orderBy(group => group.groupName, 'asc')
      .value()
  }, [])
  return (
    <List
      ref={containerRef}
      sx={theme => ({
        width: '100%',
        height: '100%',
        maxWidth: 150,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        '& ul': { padding: 0 },
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        // Hide scrollbar for IE, Edge
        msOverflowStyle: 'none',
        // Hide scrollbar for Firefox
        scrollbarWidth: 'none',
        [theme.breakpoints.down('sm')]: {
          paddingBottom: 4,
        },
        borderRadius: 0,
      })}
      subheader={<li />}
    >
      {options.map(({ groupName, postcodeDistrictGroup }) => {
        return (
          <li key={groupName}>
            <ul>
              <ListSubheader sx={{ backgroundColor: 'pageBackground' }}>{groupName}</ListSubheader>
              {postcodeDistrictGroup.map(postcodeDistrict => (
                <DistrictRow
                  key={postcodeDistrict}
                  postcodeDistrict={postcodeDistrict}
                  onChange={onChangePostcodeDistrict}
                  selected={!!selectedPostcodeDistricts[postcodeDistrict]}
                />
              ))}
            </ul>
          </li>
        )
      })}
    </List>
  )
})

export default memo(DistrictList)
