import companyService from '../../services/companyService'
import validators from '../validators'
import { PlanningCategory, allPlanningCategories } from '@leadgen/models/client'

class PlanningCategoriesForm {
  onSubmit = async (values: {
    companyId: string
    planningCategories: PlanningCategory[]
  }): Promise<void> => {
    const { companyId, planningCategories } = values
    return companyService.updatePlanningCategories({
      companyId,
      planningCategories,
    })
  }
  validatePlanningCategoriesField = validators.combineValidators(
    validators.validateRequired({
      errorMessage: 'Please enter one or more planning categories e.g. General building',
    }),
    validators.validateOptionsMulti<PlanningCategory>({
      errorMessage: 'Values must be a planning category',
      options: allPlanningCategories,
    }),
  )
}

export default new PlanningCategoriesForm()
