import { RootState } from '../../types/RootState'

class CompanyPlanningRecordsSelectors {
  getCompanyPlanningRecords = (state: RootState) =>
    state.companyPlanningRecords.companyPlanningRecords
  getPaginationTimestamp = (state: RootState) => {
    const processedTimestamp =
      state.companyPlanningRecords.companyPlanningRecords[
        state.companyPlanningRecords.companyPlanningRecords.length - 1
      ]?.processedTimestamp
    if (processedTimestamp) {
      return new Date(processedTimestamp)
    }
  }
  getLoading = (state: RootState) =>
    state.companyPlanningRecords.status === 'initial' ||
    state.companyPlanningRecords.status === 'loading'
  getInitial = (state: RootState) => state.companyPlanningRecords.status === 'initial'
  getHasError = (state: RootState) => state.companyPlanningRecords.status === 'failed'
  getError = (state: RootState) => state.companyPlanningRecords.error
}

export default new CompanyPlanningRecordsSelectors()
