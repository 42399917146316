import { createAction } from '@reduxjs/toolkit'
import { Company } from '@leadgen/models/client'

class CompanyActions {
  companyLoaded = createAction('company/companyLoaded', (company: Company) => ({
    payload: company,
  }))
  updateCompany = createAction('company/updateCompany', (company: Company) => ({
    payload: company,
  }))
  updateLastChangedTemplate = createAction(
    'company/updateLastChangedTemplate',
    ({
      lastChangedTemplateId,
      lastChangedTemplateVersion,
    }: {
      lastChangedTemplateId: string
      lastChangedTemplateVersion: number
    }) => ({
      payload: {
        lastChangedTemplateId,
        lastChangedTemplateVersion,
      },
    }),
  )
  cancelSubscription = createAction('company/cancelSubscription', () => ({
    payload: null,
  }))
  topup = createAction('company/topup', () => ({
    payload: null,
  }))
  purchaseSubscription = createAction('company/purchaseSubscription', () => ({
    payload: null,
  }))
}

export default new CompanyActions()
