import { FC, useEffect } from 'react'
import { Box } from '@mui/material'
import PSPDFKit from 'pspdfkit'
import useStableCallback from '../../../hooks/shared/useStableCallback'
import { templates } from '../../../constants/templates'
import config from '../../../config'
import TemplateTile from './TemplateTile'

const CreateTemplatePanel: FC<{
  onSaveTemplate?: () => void
  onCloseTemplate?: () => void
  lastChangedTemplateId?: string | null
  lastChangedTemplateUrl?: string | null
}> = ({ lastChangedTemplateId, lastChangedTemplateUrl, onSaveTemplate, onCloseTemplate }) => {
  const stableOnSaveTemplate = useStableCallback(() => {
    onSaveTemplate && onSaveTemplate()
  })
  const stableOnCloseTemplate = useStableCallback(() => {
    onCloseTemplate && onCloseTemplate()
  })
  useEffect(() => {
    // @ts-expect-error container not required
    PSPDFKit.preloadWorker({
      document: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}/blank.pdf`,
      baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
    })
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        component={'ul'}
        sx={{
          backgroundColor: 'pageBackground',
          display: 'grid',
          gridTemplateColumns: {
            xs: 'repeat(2, 1fr)',
            md: 'repeat(4, 1fr)',
          },
          gap: 2,
          padding: 2,
          margin: 0,
          width: '100%',
        }}
      >
        {lastChangedTemplateId && lastChangedTemplateUrl ? (
          <TemplateTile
            id={`template-edit-current`}
            url={lastChangedTemplateUrl}
            name={'Edit current template'}
            template={undefined}
            templateId={lastChangedTemplateId}
            onSaveTemplate={stableOnSaveTemplate}
            onCloseTemplate={stableOnCloseTemplate}
          />
        ) : (
          <>
            <TemplateTile
              id={`template-option-blank`}
              name={'Blank temlate'}
              template={undefined}
              onSaveTemplate={stableOnSaveTemplate}
              onCloseTemplate={stableOnCloseTemplate}
            />
            {templates.map(({ fileName, name, template }) => {
              const url = `${config.imgixBaseUrl}previews/core/${fileName}?page=1&fm=png&auto=format&fit=max&w=620&h=465`
              return (
                <TemplateTile
                  key={fileName}
                  id={`template-option-${fileName}`}
                  url={url}
                  name={name}
                  template={template}
                  onSaveTemplate={stableOnSaveTemplate}
                  onCloseTemplate={stableOnCloseTemplate}
                />
              )
            })}
          </>
        )}
      </Box>
    </Box>
  )
}

export default CreateTemplatePanel
