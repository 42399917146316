import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import companyPlanningRecordService from '../../../services/companyPlanningRecordService'
import { CompanyPlanningRecord } from '@leadgen/models/client'
import selectors from './selectors'
import { RootState } from '../../types/RootState'

class CompanyPlanningRecordsActions {
  loadCompanyPlanningRecordsFresh = createAsyncThunk(
    'companyPlanningRecords/loadCompanyPlanningRecordsFresh',
    async ({ companyId }: { companyId: string }, { dispatch }) => {
      const response = await companyPlanningRecordService.fetchCompanyPlanningRecordsFirstPage({
        companyId,
        onCompanyPlanningRecordAdded: companyPlanningRecord => {
          dispatch(this.addCompanyPlanningRecord(companyPlanningRecord))
        },
        onCompanyPlanningRecordUpdated: companyPlanningRecord => {
          dispatch(this.updateCompanyPlanningRecord(companyPlanningRecord))
        },
      })
      return response
    },
  )
  loadCompanyPlanningRecordsNext = createAsyncThunk<
    Awaited<ReturnType<typeof companyPlanningRecordService.fetchCompanyPlanningRecordsNextPage>>,
    { companyId: string },
    { state: RootState }
  >(
    'companyPlanningRecords/loadCompanyPlanningRecordsNext',
    async ({ companyId }, { dispatch, getState }) => {
      const latestTimestamp = selectors.getPaginationTimestamp(getState())
      if (!latestTimestamp) {
        throw new Error('Invalid pagination timestamp')
      }
      const response = await companyPlanningRecordService.fetchCompanyPlanningRecordsNextPage({
        companyId,
        startAfter: latestTimestamp,
        onCompanyPlanningRecordUpdated: companyPlanningRecord => {
          dispatch(this.updateCompanyPlanningRecord(companyPlanningRecord))
        },
      })
      return response
    },
  )
  addCompanyPlanningRecord = createAction(
    'companyPlanningRecords/addCompanyPlanningRecord',
    (companyPlanningRecord: CompanyPlanningRecord) => ({
      payload: companyPlanningRecord,
    }),
  )
  updateCompanyPlanningRecord = createAction(
    'companyPlanningRecords/updateCompanyPlanningRecord',
    (companyPlanningRecord: CompanyPlanningRecord) => ({
      payload: companyPlanningRecord,
    }),
  )
}

export default new CompanyPlanningRecordsActions()
