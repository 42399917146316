import { FC, memo } from 'react'
import EditPlanningCategoriesPanel from './EditPlanningCategoriesPanel'
import { useSelector } from '../../../../redux/hooks'
import authSelectors from '../../../../redux/modules/auth/selectors'
import companySelectors from '../../../../redux/modules/company/selectors'

const EditPlanningCategoriesPanelBase: FC<{ onSave?: () => void }> = ({ onSave }) => {
  const companyId = useSelector(authSelectors.getCompanyId)
  const planningCategories = useSelector(companySelectors.getCompanyPlanningCategories)
  return (
    <EditPlanningCategoriesPanel
      companyId={companyId}
      planningCategories={planningCategories}
      onSave={onSave}
    />
  )
}

export default memo(EditPlanningCategoriesPanelBase)
