import { FC } from 'react'
import EditPostcodeDistrictsPanel from './EditPostcodeDistrictsPanel'
import { useSelector } from '../../../../redux/hooks'
import authSelectors from '../../../../redux/modules/auth/selectors'
import companySelectors from '../../../../redux/modules/company/selectors'

const EditPostcodeDistrictsPanelBase: FC<{ onSave?: () => void }> = props => {
  const { onSave } = props
  const companyId = useSelector(authSelectors.getCompanyId)
  const postcodeDistricts = useSelector(companySelectors.getCompanyPostcodeDistricts)
  return (
    <EditPostcodeDistrictsPanel
      companyId={companyId}
      postcodeDistricts={postcodeDistricts}
      onSave={onSave}
    />
  )
}

export { EditPostcodeDistrictsPanelBase as EditPostcodeDistrictsPanel }
