import { FC, memo } from 'react'
import { useSubscription } from '../../../providers/SubscriptionProvider'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { CardActions } from '@mui/material'

const AdditionalTopupCard: FC = () => {
  const {
    creditsPurchasedThisMonth,
    onHandleSubmitTopup,
    additionalTopupAmount,
    topupDisabled,
    topupSubmitting,
  } = useSubscription()
  return (
    <Box
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Card variant="outlined">
        <CardContent>
          <Typography
            gutterBottom
            sx={{ color: 'text.secondary', fontSize: 14, textAlign: 'right' }}
          >
            Top up
          </Typography>
          <Typography variant="h6" component="div" gutterBottom>
            You have increased your monthly credit limit
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>adjective</Typography> */}
          <Typography variant="body2">
            Would you like to top up an additional {additionalTopupAmount} credit(s) for £
            {additionalTopupAmount * 3}? This will bring your monthly total in line with the{' '}
            {creditsPurchasedThisMonth} credits you have purchased this month?
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            variant="outlined"
            onClick={onHandleSubmitTopup}
            disabled={topupDisabled}
            loading={topupSubmitting}
            loadingPosition="end"
          >
            top up for £{additionalTopupAmount * 3}
          </Button>
        </CardActions>
      </Card>
    </Box>
  )
}

export default memo(AdditionalTopupCard)
