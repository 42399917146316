import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import templateService from '../../../services/templateService'
import { Template } from '@leadgen/models/client'

class TemplatesActions {
  loadTemplates = createAsyncThunk(
    'templates/loadTemplates',
    async ({ companyId }: { companyId: string }, { dispatch }) => {
      const response = await templateService.fetchTemplates({
        companyId,
        onTemplateUpdated: template => {
          dispatch(this.updateTemplate(template))
        },
        onTemplateAdded: template => {
          dispatch(this.addTemplate(template))
        },
      })
      return response
    },
  )
  updateTemplate = createAction('template/updateTemplate', (template: Template) => ({
    payload: template,
  }))
  addTemplate = createAction('template/addTemplate', (template: Template) => ({
    payload: template,
  }))
}

export default new TemplatesActions()
