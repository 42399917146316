import { FC, memo } from 'react'
import { PostcodeDistrict } from '@leadgen/models/client'
import useCallbackDebounced from '../../../../hooks/shared/useCallbackDebounced'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { Typography } from '@mui/material'

const DistrictRow: FC<{
  postcodeDistrict: PostcodeDistrict
  selected: boolean
  onChange: (postcodeDistrict: PostcodeDistrict) => void
}> = ({ postcodeDistrict, selected, onChange }) => {
  const onClick = useCallbackDebounced(() => {
    onChange(postcodeDistrict)
  }, [postcodeDistrict, onChange])
  return (
    <ListItem
      id={`district-list-item-${postcodeDistrict}`}
      onClick={onClick}
      sx={theme => ({
        [theme.breakpoints.down('sm')]: {
          paddingLeft: 1,
          paddingRight: 4,
        },
      })}
      secondaryAction={
        <Checkbox
          edge="end"
          sx={theme => ({
            '&:hover': {
              backgroundColor: 'transparent',
            },
            [theme.breakpoints.down('sm')]: {
              paddingLeft: 0,
              paddingRight: 0,
            },
          })}
          onChange={onClick}
          checked={selected}
        />
      }
    >
      <ListItemText
        primary={
          <Typography
            variant={'subtitle2'}
            sx={theme => ({
              fontSize: 12,
              color: theme.palette.text.secondary,
            })}
          >
            {postcodeDistrict}
          </Typography>
        }
      />
    </ListItem>
  )
}

export default memo(DistrictRow)
