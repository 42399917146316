/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { memo, ReactNode, forwardRef, useCallback } from 'react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import LinearProgress from '@mui/material/LinearProgress'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

// eslint-disable-next-line react/display-name
const StripeStyleCounterInput = forwardRef(
  (
    {
      name,
      value,
      onBlur,
      onChange,
      label,
      disabled,
      error,
      helperText,
      loading,
      fullWidth,
    }: {
      value: number
      name: string
      label: string
      error: boolean
      disabled: boolean
      loading: boolean
      helperText: ReactNode
      onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
      onChange?: (value: number) => void
      fullWidth?: boolean
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =
      useCallback(
        event => {
          if (!onChange) {
            return
          }
          const value = parseInt(event.target.value)
          onChange(Number.isNaN(value) || value < 0 ? 0 : value)
        },
        [onChange],
      )
    const handleIncrement = useCallback(() => {
      if (!onChange) {
        return
      }
      onChange(value + 1)
    }, [onChange, value])
    const handleDecrement = useCallback(() => {
      if (!onChange) {
        return
      }
      onChange(Math.max(value - 1, 0))
    }, [onChange, value])
    return (
      <Box
        sx={{
          width: fullWidth ? '100%' : 'auto',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '0.93rem',
              marginBottom: 0.5,
              lineHeight: '1.15',
              color: '#30313d',
            }}
          >
            {label}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', width: fullWidth ? '100%' : 'auto' }}>
          <Box
            sx={{
              display: 'inline-block',
              position: 'relative',
              overflow: 'hidden',
              borderRadius: 1,
              width: fullWidth ? '100%' : 'auto',
            }}
          >
            <ButtonGroup
              sx={{
                width: fullWidth ? '100%' : 'auto',
              }}
            >
              <Button
                sx={theme => ({
                  color: theme.palette.getContrastText(theme.palette.primary[50]),
                  backgroundColor: error ? 'white' : theme.palette.primary[50],
                  borderColor: error ? '#df1b41' : '#e6e6e6',
                  borderWidth: error ? '2px' : '1px',
                  borderRightWidth: '0px',
                  '&:hover': {
                    backgroundColor: error ? 'white' : theme.palette.primary[100],
                  },
                  '&:disabled': {
                    borderColor: error ? '#df1b41' : '#e6e6e6',
                    borderWidth: error ? '2px' : '1px',
                    borderRightWidth: '0px',
                  },
                })}
                onClick={handleDecrement}
                disabled={disabled || value <= 0}
              >
                <RemoveIcon
                  fontSize="small"
                  sx={theme => ({
                    color: disabled
                      ? theme.palette.primary[200]
                      : error
                        ? theme.palette.error.dark
                        : theme.palette.primary[700],
                  })}
                />
              </Button>
              <TextField
                ref={ref}
                fullWidth={fullWidth}
                onBlur={onBlur}
                onChange={handleChange}
                name={name}
                disabled={disabled}
                sx={theme => ({
                  '& .MuiOutlinedInput-root': {
                    height: '44px',
                    marginRight: '1px',
                    marginLeft: '-1px',
                    backgroundColor: error ? 'white' : 'white',

                    '& fieldset': {
                      borderRadius: 0,
                      borderColor: error ? '#df1b41' : '#e6e6e6',
                      borderWidth: error ? '2px' : '1px',
                    },
                    '&:hover fieldset': {
                      borderColor: error ? '#df1b41' : '#e6e6e6',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: error ? '#df1b41' : theme.palette.primary.main,
                      borderWidth: error ? '2px' : '1px',
                    },
                    '& input': {
                      textAlign: 'center',
                      // width: 60,
                      color: error ? theme.palette.error.dark : theme.palette.primary[700],
                      borderWidth: error ? '2px' : '1px',
                      width: fullWidth ? '100%' : '60px',
                    },
                  },
                })}
                size="small"
                value={value}
              />
              <Button
                sx={theme => ({
                  color: theme.palette.getContrastText(theme.palette.primary[50]),
                  backgroundColor: error ? 'white' : theme.palette.primary[50],
                  borderColor: error ? '#df1b41' : '#e6e6e6',
                  borderWidth: error ? '2px' : '1px',
                  borderLeftWidth: '0px',
                  '&:hover': {
                    backgroundColor: error ? 'white' : theme.palette.primary[100],
                  },
                  '&:disabled': {
                    borderColor: error ? '#df1b41' : '#e6e6e6',
                    borderWidth: error ? '2px' : '1px',
                    borderLeftWidth: '0px',
                  },
                })}
                onClick={handleIncrement}
                disabled={disabled}
              >
                <AddIcon
                  fontSize="small"
                  sx={theme => ({
                    color: disabled
                      ? theme.palette.primary[200]
                      : error
                        ? theme.palette.error.dark
                        : theme.palette.primary[700],
                  })}
                />
              </Button>
            </ButtonGroup>
            {loading && (
              <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                <LinearProgress sx={{ height: '2px' }} color={error ? 'error' : 'primary'} />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            marginBottom: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: '0.93rem',
              marginTop: 0.5,
              lineHeight: '1.15',
              color: error ? '#df1b41' : '#30313d',
            }}
          >
            {helperText}
          </Typography>
        </Box>
      </Box>
    )
  },
)

export default memo(StripeStyleCounterInput)
