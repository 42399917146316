import { FC, FocusEventHandler, memo } from 'react'
import { PlanningCategory } from '@leadgen/models/client'
import Box from '@mui/material/Box'
import useCallbackDebounced from '../../../../hooks/shared/useCallbackDebounced'
import ShowerIcon from '@mui/icons-material/Shower'
import ForestIcon from '@mui/icons-material/Forest'
import HomeIcon from '@mui/icons-material/Home'
import FormatPaintIcon from '@mui/icons-material/FormatPaint'
import WindowIcon from '@mui/icons-material/Window'
import CountertopsIcon from '@mui/icons-material/Countertops'
import TableRowsIcon from '@mui/icons-material/TableRows'
import NaturePeopleIcon from '@mui/icons-material/NaturePeople'
import Typography from '@mui/material/Typography'

const iconMap: Record<
  PlanningCategory,
  { title: string; description: string; icon: typeof ShowerIcon }
> = {
  'bathroom-fitting': {
    title: 'Bathroom fitting',
    description:
      'Planning applications where bathrooms, shower rooms, or toilets are explicitly mentioned in the application or appear in drawing plans.',
    icon: ShowerIcon,
  },
  'general-building': {
    title: 'General building',
    description:
      'Planning applications requiring general building work such as extensions, reconfiguration of layout, repairing structures, carpentry, and more.',
    icon: HomeIcon,
  },
  'kitchen-fitting': {
    title: 'Kitchen fitting',
    description:
      'Planning applications where new kitchens are explicitly mentioned in the application or appear in drawing plans.',
    icon: CountertopsIcon,
  },
  'painting-and-decorating': {
    title: 'Painting and decorating',
    description:
      'Planning applications where buildings are being built or extended, or layouts are reconfigured.',
    icon: FormatPaintIcon,
  },
  'tree-surgery': {
    title: 'Tree surgery',
    description: 'Planning applications for the removal or alterations of trees.',
    icon: ForestIcon,
  },
  flooring: {
    title: 'Flooring',
    description:
      'Planning applications where buildings are being built or extended, or layouts are reconfigured.',
    icon: TableRowsIcon,
  },
  glazing: {
    title: 'Glazing',
    description:
      'Planning applications specifically for changes glazing, or where buildings are being built or extended.',
    icon: WindowIcon,
  },
  landscaping: {
    title: 'Landscaping',
    description: 'Planning applications for changes to outdoor areas.',
    icon: NaturePeopleIcon,
  },
}

const CategoryTile: FC<{
  planningCategory: PlanningCategory
  selected: boolean
  onChange: (planningCategory: PlanningCategory) => void
  onBlur?: FocusEventHandler<HTMLLIElement>
}> = ({ planningCategory, selected, onChange, onBlur }) => {
  const onClick = useCallbackDebounced(() => {
    onChange(planningCategory)
  }, [planningCategory, onChange])
  const Icon = iconMap[planningCategory].icon
  return (
    <Box
      id={`category-tile-${planningCategory}`}
      key={planningCategory}
      component={'li'}
      aria-selected={selected}
      sx={theme => ({
        listStyle: 'none',
        padding: selected ? '14px' : '16px',
        border: selected ? '3px solid' : '1px solid',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        borderColor: selected ? 'primary.main' : theme.palette.primary['100'],
        borderRadius: 1,
        cursor: 'pointer',
        '&:hover': {
          borderColor: 'primary.200',
        },
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        backgroundColor: selected ? 'primary.50' : 'white',
      })}
      onClick={onClick}
      onBlur={onBlur}
    >
      <Box>
        <Typography variant="h6" component="span">
          {iconMap[planningCategory].title}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" component="span">
          {iconMap[planningCategory].description}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          paddingTop: 1,
        }}
      >
        <Icon />
      </Box>
    </Box>
  )
}

export default memo(CategoryTile)
