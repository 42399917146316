import { useEffect, useCallback, useState } from 'react'
import TemplatePanel from './TemplatePanel'
import { useDispatch, useSelector, useSelectorWithRef } from '../../../redux/hooks'
import templateActions from '../../../redux/modules/template/actions'
import companyActions from '../../../redux/modules/company/actions'
import templateSelectors from '../../../redux/modules/template/selectors'
import authSelectors from '../../../redux/modules/auth/selectors'
import { Template } from '@leadgen/models/client'
import axios, { AxiosError } from 'axios'

const TemplatePanelBase = ({
  templateId,
  onSave,
  onClose,
  defaultXfdf,
}: {
  templateId?: string
  onSave: () => void
  onClose: () => void
  defaultXfdf?: string
}) => {
  const [loadingXfdfError, setLoadingXfdfError] = useState<string | undefined>()
  const [loadingXfdf, setLoadingXfdf] = useState(true)
  const [xfdf, setXfdf] = useState<string | undefined>()
  const [afterCreate, setAfterCreate] = useState(false)
  const dispatch = useDispatch()
  const template = useSelector(templateSelectors.getTemplate)
  const [loading, loadingRef] = useSelectorWithRef(
    templateSelectors.getLoading({ loadingWhenInitial: false }),
  )
  const [, initialRef] = useSelectorWithRef(templateSelectors.getInitial)
  const companyId = useSelector(authSelectors.getCompanyId) as string
  const error = useSelector(templateSelectors.getError)
  useEffect(() => {
    if (
      companyId &&
      templateId &&
      !afterCreate &&
      // if the template is already loaded and there isnt a loading request pending (which would potentially make the template different in the store), we can skip reloading it
      !(template?.id === templateId && (!loadingRef.current || initialRef.current))
    ) {
      dispatch(templateActions.loadTemplate({ templateId, companyId }))
    }
  }, [companyId, dispatch, loadingRef, initialRef, afterCreate, template?.id, templateId])
  const setTemplateAfterCreate = useCallback(
    (template: Template) => {
      setAfterCreate(true)
      dispatch(templateActions.setTemplateAfterCreate(template))
      dispatch(
        companyActions.updateLastChangedTemplate({
          lastChangedTemplateId: template.id,
          lastChangedTemplateVersion: template.version,
        }),
      )
    },
    [dispatch],
  )
  const updateTemplateAfterSave = useCallback(
    (version: number) => {
      dispatch(templateActions.updateTemplateAfterSave(version))
      dispatch(
        companyActions.updateLastChangedTemplate({
          lastChangedTemplateId: templateId as string,
          lastChangedTemplateVersion: version,
        }),
      )
    },
    [dispatch, templateId],
  )
  useEffect(() => {
    if (template?.id === templateId) {
      setLoadingXfdf(true)
      axios
        .get<string>(template?.xfdfUrl as string)
        .then(response => {
          setXfdf(response.data)
          setLoadingXfdfError(undefined)
          setLoadingXfdf(false)
        })
        .catch((error: AxiosError) => {
          setLoadingXfdfError(error.message)
          setLoadingXfdf(false)
        })
    }
  }, [template?.id, template?.xfdfUrl, templateId])
  return (
    <TemplatePanel
      mode={templateId ? 'edit' : 'create'}
      loading={(loading || loadingXfdf) && !!templateId}
      loadingError={error || loadingXfdfError}
      companyId={companyId}
      template={templateId && template?.id === templateId && !!xfdf ? template : undefined}
      xfdf={xfdf}
      setTemplateAfterCreate={setTemplateAfterCreate}
      updateTemplateAfterSave={updateTemplateAfterSave}
      afterCreate={!!afterCreate}
      onClose={onClose}
      onSave={onSave}
      defaultXfdf={defaultXfdf}
    />
  )
}

export default TemplatePanelBase
