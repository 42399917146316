import { FC, memo } from 'react'
import { PaymentElement } from '@stripe/react-stripe-js'
import { Controller } from 'react-hook-form'
import monthlyCreditLimitForm from '../../../../forms/monthlyCreditLimitForm'
import { useSubscription } from '../../../providers/SubscriptionProvider'
import config from '../../../../config'
import { StripeStyleCounterInput } from '../../../atoms/StripeStyleCounterInput'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ProcessingPaymentPanel } from '../ProcessingPaymentPanel'
import { AdditionalTopupCard } from '../AdditionalTopupCard'

const SubscriptionPanel: FC = () => {
  const {
    onHandleSubmitSubscribe,
    onHandleSubmitUnsubscribe,
    monthlyCreditLimitControl,
    additionalTopupAmount,
    state,
    subscribeDisabled,
    unsubscribeDisabled,
    monthlyCreditLimitDisabled,
    topupError,
    subscribeError,
    unsubscribeError,
    monthlyCreditLimitError,
    monthlyCreditLimitSubmitting,
    subscribeSubmitting,
    unsubscribeSubmitting,
    monthlyCreditLimitInForm,
    handlePaymentElementChange,
    continueOnPaymentComplete,
  } = useSubscription()
  const error = topupError || subscribeError || unsubscribeError || monthlyCreditLimitError
  if (
    state === 'PROCESSING_PAYMENT' ||
    state === 'PROCESSING_SUBSCRIPTION' ||
    state === 'PAYMENT_COMPLETE' ||
    state === 'SUBSCRIPTION_COMPLETE'
  ) {
    return (
      <ProcessingPaymentPanel state={state} continueOnPaymentComplete={continueOnPaymentComplete} />
    )
  }
  return (
    <Box
      id="subscription-panel"
      sx={theme => ({
        overflow: 'scroll',
        width: '100%',
        padding: 2,
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: 2,
        [theme.breakpoints.down('sm')]: {
          paddingTop: '70px',
          paddingBottom: '70px',
        },
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: { xs: 0, md: 1 },
        }}
      >
        <Box
          sx={{
            marginBottom: 4,
          }}
        >
          <Typography variant="h6" component={'span'}>
            {state === 'UNSUBSCRIBED'
              ? '1. Configure your monthly credit limit'
              : 'Manage your credit monthly limit'}
          </Typography>
        </Box>
        <Controller
          name="monthlyCreditLimit"
          control={monthlyCreditLimitControl}
          render={({ field, fieldState }) => (
            <StripeStyleCounterInput
              {...field}
              helperText={fieldState.error?.message || ' '}
              error={!!fieldState.error?.message}
              label="Monthly credit limit"
              disabled={monthlyCreditLimitDisabled}
              loading={monthlyCreditLimitSubmitting}
            />
          )}
          rules={{
            validate: monthlyCreditLimitForm.validateMonthlyCreditLimitField,
          }}
        />
        {state === 'SUBSCRIBED' && (
          <>
            <Box sx={{ display: 'flex', marginLeft: '-2px', marginTop: 2, marginBottom: 0.5 }}>
              <InfoOutlinedIcon sx={{ fontSize: '26px' }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="body2">
                You can manage your monthly credit limit here.
              </Typography>
              <Typography variant="body2">
                Any unspent credits roll over each month, and we will top up your credits up to your
                limit at the start of each month.
              </Typography>
              <Typography variant="body2">You can cancel any time.</Typography>
            </Box>
          </>
        )}
        {state === 'UNSUBSCRIBED' && (
          <>
            <Box sx={{ display: 'flex', marginLeft: '-2px', marginTop: 2, marginBottom: 0.5 }}>
              <InfoOutlinedIcon sx={{ fontSize: '26px' }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="body2">
                Start by configuring your maximum monthly credit limit. Each credit equals one
                letter sent to a prospective customer.
              </Typography>
              <Typography variant="body2">
                Any unspent credits roll over each month, and we will top up your credits up to your
                limit at the start of each month.
              </Typography>
              <Typography variant="body2">You can cancel any time.</Typography>
            </Box>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        {state === 'SUBSCRIBED' && (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {additionalTopupAmount > 0 && <AdditionalTopupCard />}
            <Box
              sx={{
                flex: 1,
              }}
            />
            <Button
              variant="contained"
              onClick={onHandleSubmitUnsubscribe}
              disabled={unsubscribeDisabled}
              loading={unsubscribeSubmitting}
              loadingPosition="end"
              color="error"
            >
              cancel subscription
            </Button>
            {error && (
              <Box sx={{ marginTop: 1 }}>
                <Typography variant="body2" color={'error'}>
                  {error}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        {state === 'UNSUBSCRIBED' && (
          <>
            <Box
              sx={{
                marginBottom: 4,
              }}
            >
              <Typography variant="h6" component={'span'}>
                2. Provide payment details
              </Typography>
            </Box>
            <PaymentElement
              options={{
                readOnly: subscribeSubmitting,
              }}
              onChange={handlePaymentElementChange}
            />
            <Box
              sx={{
                marginTop: 2,
              }}
            >
              <Button
                id="subscription-subscribe"
                onClick={onHandleSubmitSubscribe}
                disabled={subscribeDisabled}
                fullWidth
                variant="contained"
                loading={subscribeSubmitting}
                loadingPosition="end"
              >
                {monthlyCreditLimitInForm
                  ? `subscribe for £${monthlyCreditLimitInForm * 3} per month`
                  : 'subscribe'}
              </Button>
            </Box>
            {error && (
              <Box sx={{ marginTop: 1 }}>
                <Typography variant="body2" color={'error'}>
                  {error}
                </Typography>
              </Box>
            )}
            {(config.local || config.dev) && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, marginTop: 4 }}>
                <Typography variant={'body2'}>
                  4000 0025 0000 3155 SCA with push notification etc
                </Typography>
                <Typography variant={'body2'}>4000 0000 0000 3063 3DS</Typography>
                <Typography variant={'body2'}>4242 4242 4242 4242 success</Typography>
                <Typography variant={'body2'}>4000 0000 0000 0341 fail</Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default memo(SubscriptionPanel)
