import { useState, useMemo, useContext, createContext, useRef } from 'react'
import { Dialog } from '@mui/material'
import useCallbackDebounced from '../../../hooks/shared/useCallbackDebounced'
import TemplatePanel from '../../molecules/TemplatePanel'

const EditTemplateDialogContext = createContext<{
  openEditTemplateDialog: (
    args:
      | {
          templateId: string
          onSave?: () => void
          onClose?: () => void
        }
      | {
          defaultXfdf: string
          onSave?: () => void
          onClose?: () => void
        },
  ) => void

  closeEditTemplateDialog: () => void
} | null>(null)

const useEditTemplateDialog = () => {
  const context = useContext(EditTemplateDialogContext)
  if (!context) {
    throw new Error('no EditTemplateDialogProvider found when calling useEditTemplateDialog()')
  }
  return context
}

const EditTemplateDialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [templateId, setTemplateId] = useState<string>()
  const onSaveRef = useRef<() => void>()
  const onCloseRef = useRef<() => void>()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [defaultXfdf, setDefaultXfdf] = useState<string>()
  const openEditTemplateDialog = useCallbackDebounced(
    ({
      templateId,
      onSave,
      onClose,
      defaultXfdf,
    }: {
      templateId?: string
      onSave?: () => void
      onClose?: () => void
      defaultXfdf?: string
    }) => {
      setTemplateId(templateId)
      setDefaultXfdf(defaultXfdf)
      onSaveRef.current = onSave
      onCloseRef.current = onClose
      setOpen(true)
    },
    [],
  )
  const closeEditTemplateDialog = useCallbackDebounced(() => {
    onCloseRef.current && onCloseRef.current()
    onSaveRef.current = undefined
    onCloseRef.current = undefined
    setTemplateId(undefined)
    setDefaultXfdf(undefined)
    setOpen(false)
  }, [])
  const onSaveEditTemplateDialog = useCallbackDebounced(() => {
    onSaveRef.current && onSaveRef.current()
    onSaveRef.current = undefined
    onCloseRef.current = undefined
    setTemplateId(undefined)
    setDefaultXfdf(undefined)
    setOpen(false)
  }, [])
  const value = useMemo(
    () => ({
      openEditTemplateDialog,
      closeEditTemplateDialog,
    }),
    [closeEditTemplateDialog, openEditTemplateDialog],
  )
  return (
    <EditTemplateDialogContext.Provider value={value}>
      {children}
      <Dialog fullScreen open={open} onClose={closeEditTemplateDialog} disableEscapeKeyDown={true}>
        <TemplatePanel
          onClose={closeEditTemplateDialog}
          onSave={onSaveEditTemplateDialog}
          templateId={templateId}
          defaultXfdf={defaultXfdf}
        />
      </Dialog>
    </EditTemplateDialogContext.Provider>
  )
}

export { useEditTemplateDialog, EditTemplateDialogProvider }
export default EditTemplateDialogProvider
