import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { useEditTemplateDialog } from '../../providers/EditTemplateDialogProvider'
import AddIcon from '@mui/icons-material/Add'
import useStableCallback from '../../../hooks/shared/useStableCallback'
import useCallbackDebounced from '../../../hooks/shared/useCallbackDebounced'

const TemplateTile: FC<{
  id: string
  url?: string
  name: string
  template: string
  templateId?: string
  onSaveTemplate?: () => void
  onCloseTemplate?: () => void
}> = ({ id, url, name, template, templateId, onSaveTemplate, onCloseTemplate }) => {
  const { openEditTemplateDialog } = useEditTemplateDialog()
  const stableOnSaveTemplate = useStableCallback(() => {
    onSaveTemplate && onSaveTemplate()
  })
  const stableOnCloseTemplate = useStableCallback(() => {
    onCloseTemplate && onCloseTemplate()
  })
  const handleTemplateClicked = useCallbackDebounced(() => {
    openEditTemplateDialog({
      defaultXfdf: template,
      templateId,
      onClose: stableOnCloseTemplate,
      onSave: stableOnSaveTemplate,
    })
  }, [openEditTemplateDialog, stableOnCloseTemplate, stableOnSaveTemplate, template, templateId])
  return (
    <Box
      component={'li'}
      sx={{
        listStyle: 'none',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        backgroundColor: 'pageBackground',
        height: 'auto',
        width: '100%',
      }}
    >
      <Box
        id={id}
        sx={{
          borderRadius: 1,
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          backgroundColor: 'white',
        }}
      >
        {url && (template || templateId) ? (
          <Box component={'img'} sx={{ width: '100%' }} src={url} />
        ) : (
          <Box
            component={'div'}
            sx={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              aspectRatio: '1 / 1.4',
            }}
          >
            <AddIcon
              sx={{
                fontSize: 72,
                color: 'primary.300',
              }}
            />
          </Box>
        )}
        <Box
          sx={theme => ({
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            border: `1px solid ${theme.palette.primary['100']}`,
            '&:hover': {
              borderColor: 'primary.200',
            },
            backgroundColor: 'transparent',
            borderRadius: 1,
            aspectRatio: '1 / 1.4',
          })}
          onClick={handleTemplateClicked}
        />
      </Box>
      <Box
        sx={{
          padding: 0.5,
        }}
      >
        <Typography variant="subtitle2">{name}</Typography>
      </Box>
    </Box>
  )
}

export default TemplateTile
