import { useMemo, useContext, createContext, useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from '../../../redux/hooks'
import companyService from 'src/services/companyService'
import { Unsubscribe } from 'firebase/firestore'
import { Company } from '@leadgen/models/client/Company'
import companyActions from '../../../redux/modules/company/actions'
import companySelectors from '../../../redux/modules/company/selectors'

const CompanySubscriptionContext = createContext<{
  subscribeToCompany: ({ companyId }: { companyId: string }) => Promise<Company>
} | null>(null)

const useCompanySubscription = () => {
  const context = useContext(CompanySubscriptionContext)
  if (!context) {
    throw new Error('no CompanySubscriptionProvider found when calling useCompanySubscription()')
  }
  return context
}

const CompanySubscriptionProvider = ({ children }) => {
  const dispatch = useDispatch()
  const companyId = useSelector(companySelectors.getCompanyId)
  const unsubscribeRef = useRef<Unsubscribe>()
  useEffect(() => {
    if (!companyId && unsubscribeRef.current) {
      unsubscribeRef.current()
      unsubscribeRef.current = undefined
    }
  }, [companyId])
  const subscribeToCompany = useCallback(
    async ({ companyId }: { companyId: string }) => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current()
        unsubscribeRef.current = undefined
      }
      const { data, unsubscribe } = await companyService.subscribeToCompany({
        companyId,
        onCompanyUpdated: (company: Company) => {
          dispatch(companyActions.updateCompany(company))
        },
      })
      unsubscribeRef.current = unsubscribe
      return data
    },
    [dispatch],
  )
  const value = useMemo(
    () => ({
      subscribeToCompany,
    }),
    [subscribeToCompany],
  )
  return (
    <CompanySubscriptionContext.Provider value={value}>
      {children}
    </CompanySubscriptionContext.Provider>
  )
}

export { useCompanySubscription, CompanySubscriptionProvider }
export default CompanySubscriptionProvider
