import { FC, memo } from 'react'
import { SubscriptionState } from '../../../providers/SubscriptionProvider'
import { LoaderToCompletionAnimationPanel } from '../../../atoms/LoaderToCompletionAnimationPanel'

const ProcessingPaymentPanel: FC<{
  state: Extract<
    SubscriptionState,
    | 'PROCESSING_PAYMENT'
    | 'PROCESSING_SUBSCRIPTION'
    | 'PAYMENT_COMPLETE'
    | 'SUBSCRIPTION_COMPLETE'
    | 'SUBSCRIBED'
  >
  continueOnPaymentComplete: () => void
}> = ({ state, continueOnPaymentComplete }) => {
  if (state === 'PROCESSING_PAYMENT') {
    return (
      <LoaderToCompletionAnimationPanel
        id="processing-payment-loader"
        state={'processing'}
        title="Processing payment"
        subtitle="Please stay on this page while we contact your bank"
        onAnimationComplete={continueOnPaymentComplete}
      />
    )
  }
  if (state === 'PROCESSING_SUBSCRIPTION') {
    // same as processing payment but only triggers when a user unsubscribes and resubscribes whilst already being topped up to full, or changing card details (which doesnt exist yet)
    return (
      <LoaderToCompletionAnimationPanel
        id="processing-subscription-loader"
        state={'processing'}
        title="Processing card details"
        subtitle="Please stay on this page while we contact your bank"
        onAnimationComplete={continueOnPaymentComplete}
      />
    )
  }
  if (state === 'PAYMENT_COMPLETE' || state === 'SUBSCRIBED') {
    return (
      <LoaderToCompletionAnimationPanel
        id="processing-payment-complete"
        state={'complete'}
        title="Processing payment"
        subtitle="Please stay on this page while we contact your bank"
        onAnimationComplete={continueOnPaymentComplete}
      />
    )
  }
  if (state === 'SUBSCRIPTION_COMPLETE' || state === 'SUBSCRIBED') {
    return (
      <LoaderToCompletionAnimationPanel
        id="processing-subscription-complete"
        state={'complete'}
        title="Processing card details"
        subtitle="Please stay on this page while we contact your bank"
        onAnimationComplete={continueOnPaymentComplete}
      />
    )
  }
  return null
}

export default memo(ProcessingPaymentPanel)
