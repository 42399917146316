/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { Template } from '@leadgen/models/client'
import templateForm from '../../../forms/templateForm'
import PdfViewerComponent from '../../atoms/PdfViewerComponent'
import useCallbackDebounced from '../../../hooks/shared/useCallbackDebounced'
import { useSnackbar } from '../../providers/SnackbarProvider'
import { blankXfdf } from '../../../constants/defaultXfdf'

const TemplatePanel = ({
  loading,
  loadingError,
  companyId,
  template,
  mode,
  setTemplateAfterCreate,
  updateTemplateAfterSave,
  afterCreate,
  xfdf,
  onSave,
  onClose,
  defaultXfdf = blankXfdf,
}: {
  loading: boolean
  loadingError?: string
  companyId: string
  template?: Template
  mode: 'create' | 'edit'
  setTemplateAfterCreate: (template: Template) => void
  updateTemplateAfterSave: (version: number) => void
  afterCreate: boolean
  xfdf?: string
  onSave: () => void
  onClose: () => void
  closeOnSave?: boolean
  defaultXfdf?: string
}) => {
  const { showError } = useSnackbar()
  const [error, setError] = useState<string>()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const xfdfAnnotations = mode === 'create' || (mode === 'edit' && afterCreate) ? defaultXfdf : xfdf
  const onSaveWrapped = useCallback(
    async (pdf: Blob, xfdf: Blob) => {
      setSubmitting(true)
      if (mode === 'create') {
        await templateForm
          .onSubmitCreate({
            pdf,
            xfdf,
            companyId,
          })
          .then(template => {
            setSubmitting(false)
            setError(undefined)
            setTemplateAfterCreate(template)
            onSave()
          })
          .catch((error: Error) => {
            setSubmitting(false)
            setError(error.message)
            showError(error.message)
          })
      } else {
        await templateForm
          .onSubmitUpdate({
            pdf,
            xfdf,
            companyId,
            templateId: template?.id as string,
          })
          .then(version => {
            updateTemplateAfterSave(version)
            setSubmitting(false)
            setError(undefined)
            onSave()
          })
          .catch((error: Error) => {
            setSubmitting(false)
            setError(error.message)
            showError(error.message)
          })
      }
    },
    [
      companyId,
      mode,
      onSave,
      setTemplateAfterCreate,
      showError,
      template?.id,
      updateTemplateAfterSave,
    ],
  )
  const onCloseWrapped = useCallbackDebounced(
    (hasChanges: boolean) => {
      if (hasChanges) {
        setDialogOpen(true)
      } else {
        onClose()
      }
    },
    [onClose],
  )
  const onDialogAgree = useCallbackDebounced(() => {
    setDialogOpen(false)
    onClose()
  }, [onClose])
  const onDialogDisagree = useCallbackDebounced(() => {
    setDialogOpen(false)
  }, [])
  return (
    <Box id="template-panel">
      {loading && <Typography variant="caption">loading</Typography>}
      {xfdfAnnotations && (
        <PdfViewerComponent
          saving={submitting}
          xfdf={xfdfAnnotations}
          onSave={onSaveWrapped}
          onClose={onCloseWrapped}
        />
      )}
      <Dialog open={dialogOpen} onClose={onDialogDisagree}>
        <DialogTitle id="alert-dialog-title">Exit without saving?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have unsaved changes. Are you sure you want to exit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogDisagree}>No, stay</Button>
          <Button onClick={onDialogAgree} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TemplatePanel
