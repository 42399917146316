import { RootState } from '../../types/RootState'

class PlanningRecordsSelectors {
  getPlanningRecords = (state: RootState) => state.planningRecords.planningRecords
  getPaginationTimestamp = (state: RootState) => {
    const createdTimestamp =
      state.planningRecords.planningRecords[state.planningRecords.planningRecords.length - 1]
        ?.createdTimestamp
    if (createdTimestamp) {
      return new Date(createdTimestamp)
    }
  }
  getLoading = (state: RootState) =>
    state.planningRecords.status === 'initial' || state.planningRecords.status === 'loading'
  getInitial = (state: RootState) => state.planningRecords.status === 'initial'
  getHasError = (state: RootState) => state.planningRecords.status === 'failed'
  getError = (state: RootState) => state.planningRecords.error
}

export default new PlanningRecordsSelectors()
