import { useEffect, useRef, useState } from 'react'
import useStableCallback from './useStableCallback'

export const useQuery = <T, P>({
  queryKey,
  fetchData,
  params,
}: {
  queryKey?: string
  fetchData: (params: P) => Promise<T>
  params: P
}) => {
  const [data, setData] = useState<T | undefined>(undefined)
  const dataRef = useRef<T>()
  const [loading, setLoading] = useState(queryKey ? true : false)
  const [error, setError] = useState<string | undefined>(undefined)
  const stableFetchData = useStableCallback(() => {
    setError(undefined)
    setLoading(true)
    fetchData(params)
      .then((data: T) => {
        setError(undefined)
        setLoading(false)
        dataRef.current = data
        setData(data)
      })
      .catch((error: Error) => {
        setError(error.message)
        setLoading(false)
        setData(undefined)
      })
  })
  useEffect(() => {
    if (queryKey) {
      stableFetchData()
    }
  }, [stableFetchData, queryKey])
  return { data, loading, error, fetch: stableFetchData, dataRef }
}
