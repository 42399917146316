import { PostcodeDistrict } from '@leadgen/models/client'
export const postcodeDistricts: PostcodeDistrict[] = [
  'TA3',
  'TA20',
  'DT6',
  'DT7',
  'EX1',
  'EX2',
  'EX3',
  'EX4',
  'EX5',
  'EX6',
  'EX8',
  'EX9',
  'EX10',
  'EX11',
  'EX12',
  'EX13',
  'EX14',
  'EX15',
  'EX17',
  'EX24',
]
