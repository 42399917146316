import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import planningRecordService from '../../../services/planningRecordService'
import { PlanningRecord } from '@leadgen/models/client'
import selectors from './selectors'
import { RootState } from '../../types/RootState'

class PlanningRecordsActions {
  loadPlanningRecordsFresh = createAsyncThunk(
    'planningRecords/loadPlanningRecordsFresh',
    async (params, { dispatch }) => {
      const response = await planningRecordService.fetchPlanningRecordsFirstPage({
        onPlanningRecordAdded: planningRecord => {
          dispatch(this.addPlanningRecord(planningRecord))
        },
        onPlanningRecordUpdated: planningRecord => {
          dispatch(this.updatePlanningRecord(planningRecord))
        },
      })
      return response
    },
  )
  loadPlanningRecordsNext = createAsyncThunk<
    Awaited<ReturnType<typeof planningRecordService.fetchPlanningRecordsNextPage>>,
    undefined,
    { state: RootState }
  >('planningRecords/loadPlanningRecordsNext', async (params, { dispatch, getState }) => {
    const latestTimestamp = selectors.getPaginationTimestamp(getState())
    if (!latestTimestamp) {
      throw new Error('Invalid pagination timestamp')
    }
    const response = await planningRecordService.fetchPlanningRecordsNextPage({
      startAfter: latestTimestamp,
      onPlanningRecordUpdated: planningRecord => {
        dispatch(this.updatePlanningRecord(planningRecord))
      },
    })
    return response
  })
  addPlanningRecord = createAction(
    'planningRecords/addPlanningRecord',
    (planningRecord: PlanningRecord) => ({
      payload: planningRecord,
    }),
  )
  updatePlanningRecord = createAction(
    'planningRecords/updatePlanningRecord',
    (planningRecord: PlanningRecord) => ({
      payload: planningRecord,
    }),
  )
  approvePlanningRecord = createAction(
    'planningRecords/approvePlanningRecord',
    (planningRecord: PlanningRecord) => ({
      payload: planningRecord,
    }),
  )
  discardPlanningRecord = createAction(
    'planningRecords/discardPlanningRecord',
    (planningRecord: PlanningRecord) => ({
      payload: planningRecord,
    }),
  )
}

export default new PlanningRecordsActions()
