import { query, getDocs, limit, startAfter, orderBy } from 'firebase/firestore'
import firestore from '../../firestore'
import { CompanyPlanningRecord } from '@leadgen/models/client'
import BaseService from '../BaseService'

class CompanyPlanningRecordService extends BaseService {
  readonly pageSize = 3
  fetchCompanyPlanningRecords = async ({
    companyId,
  }: {
    companyId: string
  }): Promise<CompanyPlanningRecord[]> => {
    const companyPlanningRecordsSnapshot = await getDocs(
      firestore.companyPlanningRecords(companyId),
    )
    return companyPlanningRecordsSnapshot.docs.map(companyPlanningRecordDoc =>
      companyPlanningRecordDoc.data(),
    )
  }
  fetchCompanyPlanningRecordsFirstPage = async ({
    companyId,
    onCompanyPlanningRecordAdded,
    onCompanyPlanningRecordUpdated,
  }: {
    companyId: string
    onCompanyPlanningRecordAdded: (template: CompanyPlanningRecord) => void
    onCompanyPlanningRecordUpdated: (template: CompanyPlanningRecord) => void
  }) => {
    const { data, unsubscribe } = await this.onSnapshot({
      query: query(
        firestore.companyPlanningRecords(companyId),
        orderBy('processedTimestamp', 'desc'),
        limit(this.pageSize),
      ),
      onUpdated: onCompanyPlanningRecordUpdated,
      onAdded: onCompanyPlanningRecordAdded,
    })
    return {
      data,
      unsubscribe,
    }
  }
  fetchCompanyPlanningRecordsNextPage = async ({
    companyId,
    startAfter: startAfterTimestamp,
    onCompanyPlanningRecordUpdated,
  }: {
    companyId: string
    startAfter: Date
    onCompanyPlanningRecordUpdated: (template: CompanyPlanningRecord) => void
  }) => {
    const { data, unsubscribe } = await this.onSnapshot({
      query: query(
        firestore.companyPlanningRecords(companyId),
        orderBy('processedTimestamp', 'desc'),
        startAfter(startAfterTimestamp),
        limit(this.pageSize),
      ),
      onUpdated: onCompanyPlanningRecordUpdated,
    })
    return {
      data,
      unsubscribe,
    }
  }
}

export default new CompanyPlanningRecordService()
