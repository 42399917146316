/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import planningCategoriesForm from '../../../../forms/planningCategoriesForm'
import { PlanningCategory } from '@leadgen/models/client'
import useCallbackDebounced from '../../../../hooks/shared/useCallbackDebounced'
import CategoryGrid from '../CategoryGrid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

const defaultPlanningCategories = []

const EditPlanningCategoriesPanel: FC<{
  onSave?: () => void
  companyId: string | null
  planningCategories: PlanningCategory[] | null
}> = ({ onSave, companyId, planningCategories }) => {
  const { control, handleSubmit, formState, reset, setError, watch } = useForm<{
    planningCategories: PlanningCategory[]
  }>({
    mode: 'onChange',
    defaultValues: {
      planningCategories: planningCategories || defaultPlanningCategories,
    },
  })
  const formPlanningCategories = watch('planningCategories')
  useEffect(() => {
    reset(
      {
        planningCategories: planningCategories || defaultPlanningCategories,
      },
      { keepDirtyValues: true },
    )
  }, [planningCategories, reset])
  const onSubmit: SubmitHandler<{
    planningCategories: PlanningCategory[]
  }> = useCallbackDebounced(
    ({ planningCategories }: { planningCategories: PlanningCategory[] }) => {
      if (companyId == null) {
        return
      }
      return planningCategoriesForm
        .onSubmit({
          companyId,
          planningCategories,
        })
        .then(() => {
          reset(undefined, { keepValues: true, keepDirty: false, keepDefaultValues: false })
          onSave && onSave()
        })
        .catch((error: Error) => {
          setError('root', { message: error.message })
        })
    },
    [companyId, reset, setError, onSave],
  )

  return (
    <Box
      component="form"
      id="edit-planning-categories-panel"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Controller
        name="planningCategories"
        control={control}
        render={({ field }) => (
          <Box
            sx={theme => ({
              overflow: 'scroll',
              [theme.breakpoints.down('sm')]: {
                paddingTop: '70px',
                paddingBottom: '70px',
              },
            })}
          >
            <CategoryGrid planningCategories={field.value} {...field} />
          </Box>
        )}
        rules={{
          validate: planningCategoriesForm.validatePlanningCategoriesField,
        }}
      />
      <Box
        sx={theme => ({
          position: 'absolute',
          bottom: 30,
          right: 30,
          borderRadius: 1,
          [theme.breakpoints.down('sm')]: {
            pointerEvents: 'none',
            bottom: 0,
            right: 0,
            left: 0,
          },
        })}
      >
        <Box
          sx={theme => ({
            position: 'relative',
            flex: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('sm')]: {
              paddingTop: 2,
              paddingBottom: 2,
              paddingRight: 2,
              paddingLeft: 1,
            },
          })}
        >
          <Box
            sx={theme => ({
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              [theme.breakpoints.down('sm')]: {
                backgroundColor: 'pageBackground',
                ['-webkit-mask-image']:
                  '-webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))',
              },
            })}
          />
          <Box
            sx={{
              marginRight: 2,
            }}
          >
            {formState.errors.root?.message && (
              <Typography color="error" variant="body2">
                {formState.errors.root.message}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              backgroundColor: 'pageBackground',
            }}
          >
            <Button
              sx={{
                pointerEvents: 'visible',
              }}
              id="edit-postcode-districts-onboarding-save"
              variant="contained"
              type="submit"
              disabled={
                formState.isSubmitting ||
                !formPlanningCategories.length ||
                !formState.dirtyFields.planningCategories
              }
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default EditPlanningCategoriesPanel
