import { useEffect, useMemo, useState } from 'react'
import { getAuth, User } from 'firebase/auth'
import { useDispatch } from '../../redux/hooks'
import companySelectors from '../../redux/modules/company/selectors'
import { useCompanySubscription } from '../../components/providers/CompanySubscriptionProvider'
import { store } from '../../redux/store'

export const useSubscribeToCompanyChanges = () => {
  const dispatch = useDispatch()
  const initialCompanyId = useMemo(() => {
    return companySelectors.getCompanyId(store.getState())
  }, [])
  const { subscribeToCompany } = useCompanySubscription()
  const [currentUser, setCurrentUser] = useState<User | null>()
  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(currentUser => {
      setCurrentUser(currentUser)
      unsubscribe()
    })
    return unsubscribe
  }, [dispatch])

  useEffect(() => {
    // subscribe to company if login state is rehydrated and the user is logged in (meaning they dont need to call login or register to subscribe to company changes)
    if (initialCompanyId && currentUser !== undefined && currentUser !== null) {
      subscribeToCompany({ companyId: initialCompanyId })
    }
  }, [currentUser, initialCompanyId, subscribeToCompany])
}
