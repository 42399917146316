import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext,
  createContext,
  useCallback,
  FC,
  PropsWithChildren,
} from 'react'
import { useStripe, useElements, Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe, StripeElements, StripeElementsOptions } from '@stripe/stripe-js'
import config from '../../../config'
import { Control, FieldValues, FormState, SubmitHandler, useForm } from 'react-hook-form'
import subscribeForm from '../../../forms/subscribeForm'
import unsubscribeForm from '../../../forms/unsubscribeForm'
import topupForm from '../../../forms/topupForm'
import monthlyCreditLimitForm from '../../../forms/monthlyCreditLimitForm'
import useCallbackDebounced from '../../../hooks/shared/useCallbackDebounced'
import useCallbackDebouncedAsync from '../../../hooks/shared/useCallbackDebouncedAsync'
import useStableCallback from '../../../hooks/shared/useStableCallback'
import { useAutoSave } from '../../../hooks/form/useAutosave'
import { useStateWithRef } from '../../../hooks/shared/useStateWithRef'
import { useSelector, useDispatch } from '../../../redux/hooks'
import companySelectors from '../../../redux/modules/company/selectors'
import companyActions from '../../../redux/modules/company/actions'
import authSelectors from '../../../redux/modules/auth/selectors'
import _ from 'lodash'
import { useTheme } from '@mui/material'
import companyService from '../../../services/companyService'
import { store } from '../../../redux/store'

let stripePromise: Promise<Stripe | null>

const getStripe = async () => {
  if (!stripePromise) {
    stripePromise = loadStripe(config.stripeSecret)
  }
  return stripePromise
}

const withStripeProvider =
  <P extends object>(Component: React.ComponentType<P>) =>
  // eslint-disable-next-line react/display-name
  (props: P) => {
    const theme = useTheme()
    return (
      <Elements
        stripe={getStripe()}
        options={
          {
            mode: 'subscription',
            amount: 0,
            currency: 'gbp',
            paymentMethodCreation: 'manual',
            payment_method_types: ['card'],
            appearance: {
              rules: {
                // This targets the input on focus and disables the default blue outline
                '.Input': {
                  borderColor: '#c6c7cb',
                },
                '.Input:focus': {
                  outline: 'none',
                  boxShadow: 'none',
                  borderColor: theme.palette.primary.main,
                },
                '.Input:hover': {
                  borderColor: 'black',
                },
                '.Input:hover:focus': {
                  borderColor: theme.palette.primary.main,
                },
              },
              variables: {
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              },
            },
          } as StripeElementsOptions
        }
      >
        <Component {...(props as P)} />
      </Elements>
    )
  }

export type SubscriptionState =
  | 'UNSUBSCRIBED'
  | 'SUBSCRIBED'
  | 'PROCESSING_PAYMENT'
  | 'PROCESSING_SUBSCRIPTION'
  | 'PAYMENT_COMPLETE'
  | 'SUBSCRIPTION_COMPLETE'

const SubscriptionContext = createContext<{
  continueOnPaymentComplete: () => void
  handlePaymentElementChange: (event: any) => void
  credits: number | null
  pendingCredits: number | null
  creditsPurchasedThisMonth: number | null
  onHandleSubmitSubscribe: () => void
  onHandleSubmitUnsubscribe: () => void
  onHandleSubmitTopup: () => void
  monthlyCreditLimitControl: Control<
    {
      monthlyCreditLimit: number
    },
    any
  >
  formStateSubscribe: FormState<FieldValues>
  formStateUnsubscribe: FormState<FieldValues>
  formStateTopup: FormState<FieldValues>
  awaitingSubscription: boolean
  awaitingPayment: boolean
  subscriptionComplete: boolean
  paymentComplete: boolean
  additionalTopupAmount: number
  state: SubscriptionState
  processing: boolean
  subscribeDisabled: boolean
  unsubscribeDisabled: boolean
  topupDisabled: boolean
  monthlyCreditLimitDisabled: boolean
  topupError: string | undefined
  subscribeError: string | undefined
  unsubscribeError: string | undefined
  monthlyCreditLimitInForm: number | undefined
  monthlyCreditLimitError: string | undefined
  monthlyCreditLimitSubmitting: boolean
  topupSubmitting: boolean
  subscribeSubmitting: boolean
  unsubscribeSubmitting: boolean
} | null>(null)

const useSubscriptionBase = () => {
  const context = useContext(SubscriptionContext)
  if (!context) {
    throw new Error('no SubscriptionProvider found when calling useSubscription()')
  }
  return context
}

const useSubscription = ({
  onPaymentComplete = _.noop,
}: { onPaymentComplete?: () => void } = {}) => {
  const subscription = useSubscriptionBase()
  const onPaymentCompleteStable = useStableCallback(onPaymentComplete)
  const onPaymentCompleteTrapRef = useRef(
    subscription.formStateSubscribe.isSubmitSuccessful &&
      (subscription.paymentComplete ||
        (subscription.subscriptionComplete && !subscription.awaitingPayment)),
  )
  useEffect(() => {
    const paymentComplete =
      subscription.formStateSubscribe.isSubmitSuccessful &&
      (subscription.paymentComplete ||
        (subscription.subscriptionComplete && !subscription.awaitingPayment))
    if (!paymentComplete && onPaymentCompleteTrapRef.current) {
      onPaymentCompleteStable()
      onPaymentCompleteTrapRef.current = false
      return
    }
    if (paymentComplete) {
      onPaymentCompleteTrapRef.current = true
    }
  }, [
    onPaymentCompleteStable,
    subscription.awaitingPayment,
    subscription.formStateSubscribe.isSubmitSuccessful,
    subscription.paymentComplete,
    subscription.subscriptionComplete,
  ])
  return subscription
}

const getState = ({
  hasActiveSubscription,
  awaitingSubscription,
  awaitingPayment,
  paymentComplete,
  subscriptionComplete,
  isSubscribeSuccessful,
  isTopupSuccessful,
}: {
  hasActiveSubscription: boolean
  awaitingSubscription: boolean
  awaitingPayment: boolean
  paymentComplete: boolean
  subscriptionComplete: boolean
  isSubscribeSuccessful: boolean
  isTopupSuccessful: boolean
}): SubscriptionState => {
  if ((isSubscribeSuccessful || isTopupSuccessful) && awaitingPayment) {
    return 'PROCESSING_PAYMENT'
  }
  if ((isSubscribeSuccessful || isTopupSuccessful) && paymentComplete) {
    return 'PAYMENT_COMPLETE'
  }
  if (isSubscribeSuccessful && awaitingSubscription) {
    return 'PROCESSING_SUBSCRIPTION'
  }
  if (isSubscribeSuccessful && subscriptionComplete) {
    return 'SUBSCRIPTION_COMPLETE'
  }
  if (hasActiveSubscription) {
    return 'SUBSCRIBED'
  }
  return 'UNSUBSCRIBED'
}

const useGetPaymentComplete = () => {
  const [awaitingSubscription, setAwaitingSubscription, awaitingSubscriptionRef] =
    useStateWithRef<boolean>(false)
  const [awaitingPayment, setAwaitingPayment, awaitingPaymentRef] = useStateWithRef<boolean>(false)
  const [paymentComplete, setPaymentComplete] = useState<boolean>(false)
  const [subscriptionComplete, setSubscriptionComplete] = useState<boolean>(false)
  const subscriptionAwaitingSync = useSelector(companySelectors.getSubscriptionAwaitingSync)
  const paymentAwaitingSync = useSelector(companySelectors.getPaymentAwaitingSync)
  const awaitingSyncStartedRef = useRef(false)
  useEffect(() => {
    if ((subscriptionAwaitingSync || paymentAwaitingSync) && !awaitingSyncStartedRef.current) {
      awaitingSyncStartedRef.current = true
      if (subscriptionAwaitingSync) {
        setAwaitingSubscription(true)
      }
      if (paymentAwaitingSync) {
        setAwaitingPayment(true)
      }
      setPaymentComplete(false)
      setSubscriptionComplete(false)
      return
    }
    if (awaitingSyncStartedRef.current && (!subscriptionAwaitingSync || !paymentAwaitingSync)) {
      if (!subscriptionAwaitingSync && awaitingSubscriptionRef.current) {
        setAwaitingSubscription(false)
        setSubscriptionComplete(true)
      }
      if (!paymentAwaitingSync && awaitingPaymentRef.current) {
        setAwaitingPayment(false)
        setPaymentComplete(true)
      }
      if (!awaitingSubscriptionRef.current && !awaitingPaymentRef.current) {
        awaitingSyncStartedRef.current = false
      }
    }
  }, [
    awaitingPaymentRef,
    awaitingSubscriptionRef,
    paymentAwaitingSync,
    setAwaitingPayment,
    setAwaitingSubscription,
    subscriptionAwaitingSync,
  ])
  return { awaitingSubscription, awaitingPayment, subscriptionComplete, paymentComplete }
}

const SubscriptionProvider: FC<PropsWithChildren> = withStripeProvider(({ children }) => {
  const dispatch = useDispatch()
  const stripe = useStripe() as Stripe
  const elements = useElements() as StripeElements
  const companyId = useSelector(authSelectors.getCompanyId)
  const { awaitingSubscription, awaitingPayment, subscriptionComplete, paymentComplete } =
    useGetPaymentComplete()
  const monthlyCreditLimit = useSelector(companySelectors.getCompanyMonthlyCreditLimit)
  const creditsPurchasedThisMonth = useSelector(
    companySelectors.getCompanyCreditsPurchasedThisMonth,
  )
  const hasActiveSubscription = useSelector(companySelectors.getHasActiveSubscription)
  const purchaseSubscription = useCallback(() => {
    dispatch(companyActions.purchaseSubscription())
  }, [dispatch])
  const cancelSubscription = useCallback(() => {
    dispatch(companyActions.cancelSubscription())
  }, [dispatch])
  const topup = useCallback(() => {
    dispatch(companyActions.topup())
  }, [dispatch])
  const [additionalTopupAmount, setAdditionalTopupAmount, additionalTopupAmountRef] =
    useStateWithRef<number>(
      Math.max((monthlyCreditLimit || 0) - (creditsPurchasedThisMonth || 0), 0),
    )
  const {
    reset: resetSubscribeForm,
    handleSubmit: handleSubmitSubscribe,
    formState: formStateSubscribe,
    setError: setErrorSubscribe,
  } = useForm()
  const {
    reset: resetUnsubscribeForm,
    handleSubmit: handleSubmitUnsubscribe,
    formState: formStateUnsubscribe,
    setError: setErrorUnsubscribe,
  } = useForm()
  const {
    reset: resetTopupForm,
    handleSubmit: handleSubmitTopup,
    formState: formStateTopup,
    setError: setErrorTopup,
  } = useForm()
  const {
    control,
    watch: watchMonthlyCreditLimit,
    handleSubmit: handleSubmitMonthlyCreditLimit,
    formState: formStateMonthlyCreditLimit,
    setError: setErrorMonthlyCreditLimit,
  } = useForm<{
    monthlyCreditLimit: number
  }>({
    mode: 'onChange',
    defaultValues: {
      monthlyCreditLimit: (monthlyCreditLimit || 0) as number,
    },
  })
  const state = getState({
    awaitingSubscription,
    awaitingPayment,
    hasActiveSubscription,
    isSubscribeSuccessful: formStateSubscribe.isSubmitSuccessful,
    isTopupSuccessful: formStateTopup.isSubmitSuccessful,
    paymentComplete,
    subscriptionComplete,
  })
  const onSubmitMonthlyCreditLimit: SubmitHandler<{
    monthlyCreditLimit: number
  }> = useCallbackDebouncedAsync(
    async ({ monthlyCreditLimit }) => {
      if (!companyId) {
        throw new Error('Failed to submit. Please try again.')
      }
      await monthlyCreditLimitForm
        .onSubmit({ companyId, monthlyCreditLimit })
        .then(() => {
          if (state === 'SUBSCRIBED' && creditsPurchasedThisMonth) {
            setAdditionalTopupAmount(Math.max(monthlyCreditLimit - creditsPurchasedThisMonth, 0))
          } else {
            if (additionalTopupAmountRef.current > 0) {
              setAdditionalTopupAmount(0)
            }
          }
        })
        .catch((error: Error) => {
          setErrorMonthlyCreditLimit('root', { message: error.message })
        })
    },
    [
      additionalTopupAmountRef,
      companyId,
      creditsPurchasedThisMonth,
      setAdditionalTopupAmount,
      setErrorMonthlyCreditLimit,
      state,
    ],
    600,
  )
  const onSubmitSubscribe = useCallbackDebounced(async () => {
    if (!stripe) {
      setErrorSubscribe('root', { message: 'Payment form is not ready. Please try again shortly.' })
      return
    }
    const companyId = companySelectors.getCompanyId(store.getState())
    if (!companyId) {
      setErrorSubscribe('root', {
        message: 'Payment form is not ready. Please reload the page and try again.',
      })
      return
    }
    const company = await companyService.fetchCompany({ companyId })
    const currentCompany = companySelectors.getCompany(store.getState())
    dispatch(companyActions.companyLoaded(company))
    if (
      currentCompany?.credits !== currentCompany?.credits ||
      currentCompany?.creditsPurchased !== currentCompany?.creditsPurchased ||
      currentCompany?.creditsPurchasedYearMonth !== currentCompany?.creditsPurchasedYearMonth
    ) {
      setErrorSubscribe('root', {
        message: 'Credit data was stale. Please review the information on screen and try again.',
      })
      return
    }
    await subscribeForm
      .onSubmit({ elements, stripe })
      .then(() => {
        purchaseSubscription()
      })
      .catch((error: Error) => {
        setErrorSubscribe('root', { message: error.message })
      })
  }, [stripe, dispatch, elements, setErrorSubscribe, purchaseSubscription])
  const onSubmitUnsubscribe = useCallbackDebounced(async () => {
    await unsubscribeForm
      .onSubmit()
      .then(() => {
        cancelSubscription()
        setAdditionalTopupAmount(0)
        resetUnsubscribeForm()
      })
      .catch((error: Error) => {
        setErrorUnsubscribe('root', { message: error.message })
      })
  }, [setErrorUnsubscribe, cancelSubscription, resetUnsubscribeForm, setAdditionalTopupAmount])
  const onSubmitTopup = useCallbackDebounced(async () => {
    const companyId = companySelectors.getCompanyId(store.getState())
    if (!companyId) {
      setErrorSubscribe('root', {
        message: 'Payment form is not ready. Please reload the page and try again.',
      })
      return
    }
    const company = await companyService.fetchCompany({ companyId })
    const currentCompany = companySelectors.getCompany(store.getState())
    dispatch(companyActions.companyLoaded(company))
    if (
      currentCompany?.credits !== currentCompany?.credits ||
      currentCompany?.creditsPurchased !== currentCompany?.creditsPurchased ||
      currentCompany?.creditsPurchasedYearMonth !== currentCompany?.creditsPurchasedYearMonth
    ) {
      setErrorSubscribe('root', {
        message: 'Credit data was stale. Please review the information on screen and try again.',
      })
      return
    }
    await topupForm
      .onSubmit()
      .then(() => {
        topup()
        setAdditionalTopupAmount(0)
        resetTopupForm()
      })
      .catch((error: Error) => {
        setErrorTopup('root', { message: error.message })
      })
  }, [dispatch, resetTopupForm, setAdditionalTopupAmount, setErrorSubscribe, setErrorTopup, topup])
  const onHandleSubmitMonthlyCreditLimit = useStableCallback(
    handleSubmitMonthlyCreditLimit(onSubmitMonthlyCreditLimit),
  )
  const onHandleSubmitSubscribe = useStableCallback(handleSubmitSubscribe(onSubmitSubscribe))
  const onHandleSubmitUnsubscribe = useStableCallback(handleSubmitUnsubscribe(onSubmitUnsubscribe))
  const onHandleSubmitTopup = useStableCallback(handleSubmitTopup(onSubmitTopup))
  useAutoSave({
    watch: watchMonthlyCreditLimit,
    submit: onHandleSubmitMonthlyCreditLimit,
  })
  const continueOnPaymentComplete = useStableCallback(() => {
    // trigger resetSubscribeForm when the payment animation completes to set the subscription page to display the new subscription
    if (
      formStateSubscribe.isSubmitSuccessful &&
      (paymentComplete || (subscriptionComplete && !awaitingPayment))
    ) {
      resetSubscribeForm()
    }
    if (formStateTopup.isSubmitSuccessful && paymentComplete) {
      resetTopupForm()
    }
  })
  const monthlyCreditLimitInForm = watchMonthlyCreditLimit('monthlyCreditLimit')
  const credits = useSelector(companySelectors.getCompanyCredits)
  const pendingCredits = useSelector(companySelectors.getCompanyPendingCredits)
  const [stripeFormCompleted, setStripeFormCompleted] = useState(false)
  const handlePaymentElementChange = useCallback((event: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    setStripeFormCompleted(!!event.complete)
  }, [])
  const value = useMemo(
    () => ({
      credits,
      pendingCredits,
      creditsPurchasedThisMonth,
      onHandleSubmitSubscribe,
      onHandleSubmitUnsubscribe,
      onHandleSubmitTopup,
      monthlyCreditLimitControl: control,
      formStateSubscribe,
      formStateUnsubscribe,
      formStateTopup,
      awaitingSubscription,
      awaitingPayment,
      subscriptionComplete,
      paymentComplete,
      additionalTopupAmount,
      monthlyCreditLimitInForm,
      state,
      processing:
        !stripe ||
        formStateSubscribe.isSubmitting ||
        formStateUnsubscribe.isSubmitting ||
        formStateTopup.isSubmitting ||
        formStateMonthlyCreditLimit.isSubmitting ||
        state === 'PROCESSING_PAYMENT' ||
        state === 'PAYMENT_COMPLETE' ||
        state === 'SUBSCRIPTION_COMPLETE' ||
        state === 'PROCESSING_SUBSCRIPTION',
      subscribeDisabled:
        !stripeFormCompleted ||
        !monthlyCreditLimitInForm ||
        !stripe ||
        formStateSubscribe.isSubmitting ||
        formStateMonthlyCreditLimit.isSubmitting ||
        !!formStateMonthlyCreditLimit.errors.root?.message ||
        state === 'PROCESSING_PAYMENT' ||
        state === 'PAYMENT_COMPLETE' ||
        state === 'SUBSCRIPTION_COMPLETE' ||
        state === 'PROCESSING_SUBSCRIPTION',
      unsubscribeDisabled:
        !stripe ||
        formStateUnsubscribe.isSubmitting ||
        formStateTopup.isSubmitting ||
        formStateMonthlyCreditLimit.isSubmitting ||
        !!formStateMonthlyCreditLimit.errors.root?.message ||
        state === 'PROCESSING_PAYMENT' ||
        state === 'PAYMENT_COMPLETE' ||
        state === 'SUBSCRIPTION_COMPLETE' ||
        state === 'PROCESSING_SUBSCRIPTION',
      topupDisabled:
        !stripe ||
        formStateTopup.isSubmitting ||
        formStateUnsubscribe.isSubmitting ||
        formStateMonthlyCreditLimit.isSubmitting ||
        !!formStateMonthlyCreditLimit.errors.root?.message ||
        state === 'PROCESSING_PAYMENT' ||
        state === 'PAYMENT_COMPLETE' ||
        state === 'SUBSCRIPTION_COMPLETE' ||
        state === 'PROCESSING_SUBSCRIPTION',
      monthlyCreditLimitDisabled:
        formStateSubscribe.isSubmitting ||
        formStateUnsubscribe.isSubmitting ||
        formStateTopup.isSubmitting ||
        state === 'PROCESSING_PAYMENT' ||
        state === 'PAYMENT_COMPLETE' ||
        state === 'SUBSCRIPTION_COMPLETE' ||
        state === 'PROCESSING_SUBSCRIPTION',
      topupError: formStateTopup.errors.root?.message,
      subscribeError: formStateSubscribe.errors.root?.message,
      unsubscribeError: formStateUnsubscribe.errors.root?.message,
      monthlyCreditLimitError: formStateMonthlyCreditLimit.errors.root?.message,
      monthlyCreditLimitSubmitting: formStateMonthlyCreditLimit.isSubmitting,
      topupSubmitting: formStateTopup.isSubmitting,
      subscribeSubmitting: formStateSubscribe.isSubmitting,
      unsubscribeSubmitting: formStateUnsubscribe.isSubmitting,
      handlePaymentElementChange,
      continueOnPaymentComplete,
    }),
    [
      credits,
      pendingCredits,
      creditsPurchasedThisMonth,
      monthlyCreditLimitInForm,
      additionalTopupAmount,
      awaitingPayment,
      awaitingSubscription,
      control,
      formStateMonthlyCreditLimit.errors.root?.message,
      formStateMonthlyCreditLimit.isSubmitting,
      formStateSubscribe,
      formStateTopup,
      formStateUnsubscribe,
      onHandleSubmitSubscribe,
      onHandleSubmitTopup,
      onHandleSubmitUnsubscribe,
      paymentComplete,
      state,
      stripe,
      subscriptionComplete,
      handlePaymentElementChange,
      stripeFormCompleted,
      continueOnPaymentComplete,
    ],
  )
  return <SubscriptionContext.Provider value={value}>{children}</SubscriptionContext.Provider>
})

export { useSubscription, SubscriptionProvider }
export default SubscriptionProvider
