import { createSlice } from '@reduxjs/toolkit'
import { Template } from '@leadgen/models/client'
import authActions from '../auth/actions'
import actions from './actions'

export interface TemplateState {
  template?: Template
  error?: string
  currentRequest?: string
  status: 'initial' | 'idle' | 'loading' | 'failed'
}

const initialState: TemplateState = {
  template: undefined,
  status: 'initial',
}

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.loggedOut.type, () => {
        return initialState
      })
      .addCase(actions.setTemplateAfterCreate, (state, action) => {
        state.template = action.payload
      })
      .addCase(actions.updateTemplateAfterSave, (state, action) => {
        if (state.template) {
          state.template.version = action.payload
          const [head] = state.template.url.split('_')
          state.template.url = `${head}_${action.payload}.pdf?alt=media`
          state.template.xfdfUrl = `${head}_${action.payload}.xml?alt=media`
        }
      })
      .addCase(actions.loadTemplate.pending, (state, action) => {
        if (action.meta.arg.templateId !== state.template?.id) {
          state.template = undefined
        }
        state.currentRequest = action.meta.requestId
        state.status = 'loading'
      })
      .addCase(actions.loadTemplate.fulfilled, (state, action) => {
        if (state.currentRequest === action.meta.requestId) {
          state.status = 'idle'
          state.template = action.payload
          state.error = undefined
        }
      })
      .addCase(actions.loadTemplate.rejected, (state, action) => {
        if (state.currentRequest === action.meta.requestId) {
          state.status = 'failed'
          state.error = action.error.message
        }
      })
  },
})

export default templateSlice
