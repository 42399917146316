import { memo } from 'react'
import { useAprysePdf } from '../../../hooks/templates/useAprysePdf'
import Box from '@mui/material/Box'

const PdfViewerComponent = ({
  xfdf,
  onSave,
  onClose,
  saving,
  readOnly,
  document,
}: {
  document?: string
  readOnly?: boolean
  xfdf: string
  saving?: boolean
  onSave?: (pdf: Blob, xfdf: Blob) => Promise<void>
  onClose?: (hasChanges: boolean) => void
}) => {
  const { containerRef } = useAprysePdf({
    document,
    xfdf,
    readOnly,
    onClose,
    onSave,
    saving,
  })
  return (
    <Box
      ref={containerRef}
      sx={{ width: '100vw', height: '100vh', backgroundColor: 'pdfBackground' }}
    />
  )
}

export default memo(PdfViewerComponent)
