import { createSlice } from '@reduxjs/toolkit'
import { Company } from '@leadgen/models/client'
import authActions from '../auth/actions'
import actions from './actions'

export interface CompanyState {
  company?: Company
  subscriptionAwaitingSync: boolean
  paymentAwaitingSync: boolean
}

const initialState: CompanyState = {
  company: undefined,
  subscriptionAwaitingSync: false,
  paymentAwaitingSync: false,
}

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.loggedOut.type, () => {
        return initialState
      })
      .addCase(actions.updateCompany, (state, action) => {
        state.company = action.payload
        if (state.company.stripePaymentMethodId) {
          state.subscriptionAwaitingSync = false
        }
        if (
          state.company.stripePaymentMethodId &&
          state.company.creditsPurchased === state.company.monthlyCreditLimit
        ) {
          state.paymentAwaitingSync = false
        }
      })
      .addCase(actions.cancelSubscription, state => {
        if (state.company) {
          state.company.stripePaymentMethodId = null
        }
        state.subscriptionAwaitingSync = false
        state.paymentAwaitingSync = false
      })
      .addCase(actions.topup, state => {
        state.paymentAwaitingSync = true
      })
      .addCase(actions.purchaseSubscription, state => {
        state.subscriptionAwaitingSync = true
        if (
          state.company?.monthlyCreditLimit &&
          state.company.monthlyCreditLimit > state.company.creditsPurchased
        ) {
          state.paymentAwaitingSync = true
        }
      })
      .addCase(actions.companyLoaded, (state, action) => {
        state.company = action.payload
        if (state.company.stripePaymentMethodId) {
          state.subscriptionAwaitingSync = false
        }
        if (
          state.company.stripePaymentMethodId &&
          state.company.creditsPurchased === state.company.monthlyCreditLimit
        ) {
          state.paymentAwaitingSync = false
        }
      })
  },
})

export default companySlice
