import { FC, useEffect, useRef } from 'react'
import lottie, { AnimationItem } from 'lottie-web'
import loaderToCompletionAnimation from './loaderToCompletionAnimation.json'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

const LoaderToCompletionAnimationPanel: FC<{
  id: string
  onAnimationComplete: () => void
  state: 'processing' | 'complete'
  title: string
  subtitle: string
}> = ({ id, state, title, subtitle, onAnimationComplete }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const animationRef = useRef<AnimationItem | null>(null)
  const shouldLoopSegmentRef = useRef<boolean>(true)
  const stopLoopRef = useRef<boolean>(false)
  useEffect(() => {
    if (state === 'complete') {
      shouldLoopSegmentRef.current = false
    }
  }, [state])
  useEffect(() => {
    let timeout: NodeJS.Timeout
    animationRef.current = lottie.loadAnimation({
      container: containerRef.current as Element,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: loaderToCompletionAnimation,
    })
    const onDOMLoaded = () => {
      animationRef.current?.playSegments([0, 80], true)
    }
    const onComplete = () => {
      if (stopLoopRef.current === true) {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          onAnimationComplete()
        }, 2000)
        return
      }
      if (shouldLoopSegmentRef.current) {
        animationRef.current?.playSegments([0, 80], true)
      } else {
        stopLoopRef.current = true
        animationRef.current?.playSegments([0, 265], true)
      }
    }
    animationRef.current.addEventListener('DOMLoaded', onDOMLoaded)
    animationRef.current.addEventListener('complete', onComplete)
    return () => {
      clearTimeout(timeout)
      animationRef.current?.removeEventListener('DOMLoaded', onDOMLoaded)
      animationRef.current?.removeEventListener('complete', onComplete)
      animationRef.current?.destroy()
    }
  }, [onAnimationComplete])

  return (
    <Box id={id} sx={{ width: '100%', height: '100%', position: 'relative' }} ref={containerRef}>
      <Box
        sx={{
          position: 'absolute',
          top: {
            xs: '31%',
            md: '27%',
          },
          left: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: {
            xs: 2,
            md: 4,
          },
          paddingRight: {
            xs: 2,
            md: 4,
          },
        }}
      >
        <Typography variant="h6" component="span">
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: {
            xs: '64%',
            md: '67%',
          },
          left: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: {
            xs: 2,
            md: 4,
          },
          paddingRight: {
            xs: 2,
            md: 4,
          },
        }}
      >
        <Typography variant="body2" component="span">
          {subtitle}
        </Typography>
      </Box>
    </Box>
  )
}

export default LoaderToCompletionAnimationPanel
