export const routes = {
  home: '/',
  onboarding: '/onboarding',
  retries: '/retries',
  awaitEmailVerification: '/await-email-verification',
  verify: '/verify',
  welcome: '/welcome',
  resetPasswordSuccess: '/reset-password-success',
  resetPassword: '/reset-password',
  login: '/login',
  tokenLogin: '/token-login',
  register: '/register',
  accessDenied: '/access-denied',
}

export const publicRoutes = [
  routes.welcome,
  routes.login,
  routes.tokenLogin,
  routes.register,
  routes.resetPassword,
  routes.resetPasswordSuccess,
  routes.verify,
]

export const privateRoutes = [
  routes.home,
  routes.onboarding,
  routes.retries,
  routes.awaitEmailVerification,
]

export const getFromOrDefault = (route: string, from?: string) => {
  return from && privateRoutes.includes(from) ? from : route
}
