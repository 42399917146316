export default {
  localFunctions: false,
  localFirestore: false,
  localAuth: false,
  localStorage: false,
  local: false,
  dev: true,
  prod: false,
  bucketBaseUrl: 'https://firebasestorage.googleapis.com/v0/b/leadgen-server-dev.appspot.com/o/',
  aprysePdfLicenseKey:
    'demo:1741422352049:614054ad03000000008838e772fdd07dd2da6a9ef8a950c2d0017f1378',
  firebaseConfig: {
    apiKey: 'AIzaSyCqOlBNUyPx7oh7DcBcRZ3aO4g2sEn5eTU',
    authDomain: 'leadgen-server-dev.firebaseapp.com',
    projectId: 'leadgen-server-dev',
    storageBucket: 'leadgen-server-dev.appspot.com',
    messagingSenderId: '185348765148',
    appId: '1:185348765148:web:9c4fb556727d0cb0500b19',
    measurementId: 'G-JFSG6PL26C',
  },
  imgixBaseUrl: 'https://leadgen-dev.imgix.net/',
  stripeSecret:
    'pk_test_51QJMRfPvoAGPr3UKuh3CrOjvtV9C0sXMU6MGiydiJSfxQC1NyEs6wN3DDcCsxZYVIrWK9AKYOwFM7UhLudAbeR6J003wY1yz6e',
  getAddressApiKey:
    'dtoken_hEDzcyiWMr1f-zlFoRT5ramoXjLydwsIJCUa8dQK0cibXeEg14gPr41eyhADhPZy_ikFubF4KWmMRTG-8RrP5Yva1p2XSkgbSDr_69qeDOHRzk7g0FQ-T2z1Jz7kEN8ck8fGurQB9Gp4DMFHLwLTo2gtnDhKuxzrmVVd6Wkh6uOQRwGWDqJZwWj6Z6h06ZW94RbbWdxO5YjnkDiT_r6v5g',
}
